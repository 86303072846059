import { Avatar, Badge, Box, Button, Divider, Flex, FormControl, FormLabel, HStack, Image, Input, InputGroup, InputRightElement, Spinner, Text, useDisclosure } from "@chakra-ui/react";
// import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Logo from '../../assets/wehaulogo.png';
import './test.scss';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
  } from '@chakra-ui/react'
import { useEffect, useState } from "react";
//  import { userActionTypes } from "../../redux/constants/userActionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import { baseUrl, devUrl } from '../../config.json';

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
     }
}

const GuestRequest = (props) => {
    // const currentUser = useSelector(({ userData }) => userData.currentUser);
    const { isOpen, onOpen, onClose } = useDisclosure()
    // console.log("gutty", currentUser);

    const [details, setDetails] = useState(null);
    const [ploading, setPloading] = useState(true)

    const query = new URLSearchParams(props.location.search);
    const tripId = query.get('tripId')
    console.log("location", tripId)

    useEffect(() => {
        axios
          .get(`https://dev-app.wehaul247.com/api/v1/customer/trip/${tripId}/trip-details`, config)
          // .then((res) => console.log(res?.data?.data))
          .then((res) => setDetails(res?.data?.data))
          .finally((res) => setPloading(false))
          .catch((err) => toast.error("Trip no found!"));
      }, []);

    const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email2, setEmail2] = useState("");
  const [password2, setPassword2] = useState("");
  const [cpassword2, setCpassword2] = useState("");

    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    const [isLogin, setIsLogin] = useState(true);
  
    const [loading1, setLoading1] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

    // useEffect(() => {
    //     if(!currentUser) {
    //         onOpen()
    //     }
    // }, []);

    // const dispatch = useDispatch();

    const formData = {
        email,
        password
    }

    const login = async (e) => {
        e.preventDefault();
        setLoading1(true)
         try {
            const { data } = await axios.post(`https://dev-app.wehaul247.com/api/v1/auth/customer/login`, formData);
            console.log(data, data.token)
            setLoading1(false)
            localStorage.setItem("WEHAUL_CUSTOMER_TOKEN", data.token);
            // dispatch({ type: userActionTypes.SIGNIN_SUCCESS, payload: data });
            // onClose()
            window.location.reload();
         } catch (error) {
            console.log(error?.response?.data?.message)
            toast.error(error?.response?.data?.message, {
               position: toast.POSITION.TOP_CENTER
            })
            setLoading1(false)
         }
      }

    return (
        <Box>
            {/* <Flex justifyContent={"space-between"} paddingTop={"1rem"} height={"80px"} pl={{base: "1rem", md: "2rem", lg: "3rem"}} pr={{base: ".7rem", md: "2rem", lg: "3rem"}}>
                <Image transform={"scale(.9)"} src={Logo} />
                <Image className="animate" transform={"scale(.9)"} src={"https://i.pinimg.com/originals/c8/26/41/c8264172074eb50241381061719391fa.gif"} />
            </Flex> */}
            <Flex alignItems={"center"} justifyContent="space-between" paddingTop={".5rem"} pl={{base: "1rem", md: "2rem", lg: "3rem"}} pr={{base: "1rem", md: "2rem", lg: "3rem"}}>
                <Text fontSize={"1.3rem"} fontWeight="500" color={"#113E82"}>Request Details</Text>
                <Box textAlign={"right"}>
                    <Link to="/dashboard"><Button fontSize={"13px"} bg="#29805a" color="#fff">View Trip Status</Button></Link>
                </Box>
                
            </Flex>
            
            {ploading ? 
                  <Flex justifyContent={"center"} marginTop="8rem">
                      <Spinner size={"lg"} color="#333" />
                  </Flex>
                  :
                  <Flex flexWrap={"wrap"} mt={6}  pl={{base: "1rem", md: "2rem", lg: "3rem"}} pr={{base: "1rem", md: "2rem", lg: "3rem"}} gap="2rem" justifyContent={"space-between"}>

                  <Box flexBasis={"360px"} flexShrink="0" padding={{base: "1rem", md:"1.5rem"}} borderRadius={"6px"} border="1px solid #eee" flex="2" height={"600px"}>
                    <Text mb={2}>Pickup Point (Origin): <strong style={{color: "#113E82"}}>{details?.request?.pickUpAddress}</strong></Text>
                    <Text mb={2}>Destination: <strong style={{color: "#113E82"}}>{details?.request?.destinationAddress}</strong></Text>
                    <Text mb={2}>Vehicle Type: <strong style={{color: "#113E82"}}>{details?.request?.vehicleType}</strong></Text>
                    <Text mb={2}>Sender's Name: <strong style={{color: "#113E82"}}>{details?.request?.createdBy?.name}</strong></Text>
                    <Text mb={2}>Sender's Phone: <strong style={{color: "#113E82"}}>{details?.request?.createdBy?.phoneNumber}</strong></Text>
                    <Text mb={2}>Receiver's Name: <strong style={{color: "#113E82"}}>{details?.request?.receiverDetails?.name}</strong></Text>
                    <Text mb={2}>Receiver's Phone: <strong style={{color: "#113E82"}}>{details?.request?.receiverDetails?.phone}</strong></Text>
                    <Text mb={2}>Pickup Date: <strong style={{color: "#113E82"}}>{details?.request?.pickUpDate}</strong></Text>
                    <Text mb={2}>Pickup Time: <strong style={{color: "#113E82"}}>{details?.request?.pickUpTime}</strong></Text>
                    <Text mb={2}>Price: <strong style={{color: "#113E82"}}>₦{details?.FeeWithCommission.toLocaleString()}</strong></Text>
                    <Text mb={2}>Item(s): <strong style={{color: "#113E82"}}>{details?.request?.requestTitle}</strong></Text>
                    <Text mb={3}>Item Description: <strong style={{color: "#113E82"}}>{details?.request?.goodsDescription}</strong></Text>
                    <Divider />
                    <Box pt={4}>
                        <Text fontWeight={"500"} color="#37AB78">Driver Information</Text>
                        <Flex gap="1rem" alignItems={"center"} mt="3">
                            <Avatar size={"lg"} name="Juwon Daniels" src={details?.acceptedBy?.profilePicture} />
                            <Box>
                            <Text mb={1}><strong style={{color: "#113E82"}}>{details?.acceptedBy?.name}</strong></Text>
                            <Text mb={1}><strong style={{color: "#113E82"}}>{details?.acceptedBy?.phoneNumber}</strong></Text>
                            <Text><strong style={{color: "#113E82"}}>{details?.acceptedBy?.email}</strong></Text>
                            <Badge colorScheme={"green"}>Verified</Badge>
                            </Box>
                        </Flex>
                    </Box>
                </Box>
                <Box display={{base: "none", md: "flex"}} flexBasis={"360px"} flexShrink="0" 
                // backgroundImage="https://i.pinimg.com/originals/c8/26/41/c8264172074eb50241381061719391fa.gif"
                // backgroundRepeat={"no-repeat"} backgroundSize="contain"
                backgroundImage="https://blog.wehaul247.com/wp-content/uploads/2022/06/wehaul-247-van-delivery-1920x1000.jpg"
                backgroundSize="cover" 
                flex="1" height={"600px"}>

                </Box>
            </Flex>}


            <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg="#000000e9" />
                <ModalContent>
                {isLogin ? (
            <>
              {/* <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" /> */}
              <ModalHeader fontSize="24px">Sign In</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Sign in to your wehaul account.
              </Text>
              {/* <ModalCloseButton /> */}

              <form onSubmit={login}>
                <ModalBody>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <HStack>
                    <Text fontSize="14px">Don't Have an Account ? </Text>
                    <Text
                      onClick={() => window.location.href = "/signup"}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Sign Up
                    </Text>
                  </HStack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button type="submit" colorScheme="green">
                    {loading1 ? <Spinner /> : "Sign In"}
                  </Button>
                </ModalFooter>
              </form>
            </>
          ) : (
            <>
              {/* <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" /> */}
              <ModalHeader fontSize="24px">Create Account</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Sign up for a getcodes account.
              </Text>
              {/* <ModalCloseButton /> */}

              <form>
                <ModalBody>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">Full Name</FormLabel>
                    <Input
                      onChange={(e) => setFullname(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter name"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail2(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="email"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">
                      Phone Number
                    </FormLabel>
                    <Input
                      onChange={(e) => setPhone(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter phone"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword2(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Confirm Password
                    </FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setCpassword2(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Re-Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <HStack>
                    <Text fontSize="14px">Have an Account Already ? </Text>
                    <Text
                      onClick={() => setIsLogin(true)}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Sign In
                    </Text>
                  </HStack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button type="submit" colorScheme="green">
                    {loading2 ? <Spinner /> : "Sign Up"}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default GuestRequest;