import { Box, Button, Flex, FormControl, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Select, Spinner, Text, Textarea, useDisclosure, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import './testy.scss';
import axios from 'axios';
import { baseUrl } from '../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Illustration from './illustration.svg'

const RequestForm = () => {
  const [requestType, setRequestType] = useState("")
  const [pickupLocationType, setPickupLocationType] = useState("")
  const [pickupFloorType, setPickupFloorType] = useState("")
  const [dropoffLocationType, setDropoffLocationType] = useState("")
  const [dropoffFloorType, setDropoffFloorType] = useState("")
  const [pickupAddress, setPickupAddress] = useState("")
  const [dropoffAddress, setDropoffAddress] = useState("")
  const [pickupDate, setPickupDate] = useState("")
  const [pickupTime, setPickupTime] = useState("")
  const [senderName, setSenderName] = useState("")
  const [senderPhone, setSenderPhone] = useState("")
  const [senderEmail, setSenderEmail] = useState("")
  const [receiverName, setReceiverName] = useState("")
  const [receiverPhone, setReceiverPhone] = useState("")
  const [receiverEmail, setReceiverEmail] = useState("")
  const [additionalInfo, setAdditionalInfo] = useState("")

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState("");
  const toast = useToast()
  const history = useHistory()

  const sendRequest = async(e) => {
    e.preventDefault()
    setLoading(true)

    const datax = {
        requestType,
        pickupLocationType,
        pickupFloorType,
        dropoffLocationType,
        dropoffFloorType,
        pickupAddress,
        dropoffAddress,
        pickupDate,
        pickupTime,
        sender: {
            name: senderName,
            phoneNumber: "+234" + senderPhone.slice(1),
            email: senderEmail
        },
        receiver: {
            name: receiverName,
            phoneNumber: "+234" + receiverPhone.slice(1),
            email: receiverEmail
        },
        additionalInfo
    }
    const datax2 = {
        requestType,
        pickupLocationType,
        pickupFloorType,
        dropoffLocationType,
        dropoffFloorType,
        pickupAddress,
        dropoffAddress,
        pickupDate,
        pickupTime,
        sender: {
            name: senderName,
            phoneNumber: "+234" + senderPhone.slice(1),
        },
        receiver: {
            name: receiverName,
            phoneNumber: "+234" + receiverPhone.slice(1),
            email: receiverEmail
        },
        additionalInfo
    }
    const datax3 = {
        requestType,
        pickupLocationType,
        pickupFloorType,
        dropoffLocationType,
        dropoffFloorType,
        pickupAddress,
        dropoffAddress,
        pickupDate,
        pickupTime,
        sender: {
            name: senderName,
            phoneNumber: "+234" + senderPhone.slice(1),
            email: senderEmail
        },
        receiver: {
            name: receiverName,
            phoneNumber: "+234" + receiverPhone.slice(1),
        },
        additionalInfo
    }
    const datax4 = {
        requestType,
        pickupLocationType,
        pickupFloorType,
        dropoffLocationType,
        dropoffFloorType,
        pickupAddress,
        dropoffAddress,
        pickupDate,
        pickupTime,
        sender: {
            name: senderName,
            phoneNumber: "+234" + senderPhone.slice(1),
        },
        receiver: {
            name: receiverName,
            phoneNumber: "+234" + receiverPhone.slice(1),
        },
        additionalInfo
    }

    try {
        const {data} = await axios.post(`${baseUrl}/request-forms`, senderEmail === "" && receiverEmail !== "" ? datax2 : receiverEmail === "" && senderEmail !== "" ? datax3 : senderEmail === "" && receiverEmail === "" ? datax4 : datax4)
        console.log(data)
        onOpen()
        toast({
            // title: 'An error occured.',
            description: data?.message,
            status: 'success',
            duration: 9000,
            isClosable: true,
        })
    } catch(error) {
        console.log(error?.response?.data?.message)
        toast({
            title: 'An error occured.',
            description: error?.response?.data?.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
    } finally {
        setLoading(false)
    }
  }


  return (
    <Box borderTop="1px solid #333" px=".8rem">
        <Box maxW={"900px"} m="1.5rem auto"
        //  border="1px solid gray" 
        >
            <Text fontSize={"1.2rem"} fontWeight={"600"}>Service Request Form</Text>
            {/* <Button onClick={() => onOpen()}>go</Button> */}
            <form style={{padding: "1rem 0"}} onSubmit={sendRequest}>
                <FormControl mb="6">
                    <Select required onChange={e => setRequestType(e.target.value)} className='anime' variant='flushed' borderColor={"#000"} color={"gray"}>
                        <option selected disabled>Choose Request Type</option>
                        <option value="Moving item(s)">Moving an Item/Items</option>
                        <option value="Relocation">Relocation</option>
                    </Select>
                </FormControl>
                <Flex gap={"2rem"} alignItems={"center"} mb="6" flexWrap={"wrap"}>
                    <FormControl flex={"1 1 300px"}>
                        <Select required onChange={e => setPickupLocationType(e.target.value)} className='anime' variant='flushed' borderColor={"#000"} color={"gray"}>
                            <option selected disabled>Choose Pickup Location Type</option>
                            <option value="1 Bedroom">1 Bedroom</option>
                            <option value="2 Bedroom">2 Bedroom</option>
                            <option value="3 Bedroom">3 Bedroom</option>
                            <option value="4 Bedroom">4 Bedroom</option>
                            <option value="5 Bedroom +">5 Bedroom & above</option>
                            <option value="Not Applicable">Not Applicable</option>
                        </Select>
                    </FormControl>
                    <FormControl flex={"1 1 300px"}>
                        <Select required onChange={e => setDropoffLocationType(e.target.value)} className='anime' variant='flushed' borderColor={"#000"} color={"gray"}>
                            <option selected disabled>Choose Dropoff Location Type</option>
                            <option value="1 Bedroom">1 Bedroom</option>
                            <option value="2 Bedroom">2 Bedroom</option>
                            <option value="3 Bedroom">3 Bedroom</option>
                            <option value="4 Bedroom">4 Bedroom</option>
                            <option value="5 Bedroom +">5 Bedroom & above</option>
                            <option value="Not Applicable">Not Applicable</option>
                        </Select>
                    </FormControl>
                </Flex>
                <Flex gap={"2rem"} alignItems={"center"} mb="6" flexWrap={"wrap"}>
                    <FormControl flex={"1 1 300px"}>
                        <Select required onChange={e => setPickupFloorType(e.target.value)} className='anime' variant='flushed' borderColor={"#000"} color={"gray"}>
                            <option selected disabled>Choose Pickup Floor Type</option>
                            <option value="1st Floor">1st Floor</option>
                            <option value="2nd Floor">2nd Floor</option>
                            <option value="3rd Floor">3rd Floor</option>
                            <option value="4th Floor">4th Floor</option>
                            <option value="5th Floor +">5th Floor & above</option>
                        </Select>
                    </FormControl>
                    <FormControl flex={"1 1 300px"}>
                        <Select required onChange={e => setDropoffFloorType(e.target.value)} className='anime' variant='flushed' borderColor={"#000"} color={"gray"}>
                            <option selected disabled>Choose Dropoff Floor Type</option>
                            <option value="1st Floor">1st Floor</option>
                            <option value="2nd Floor">2nd Floor</option>
                            <option value="3rd Floor">3rd Floor</option>
                            <option value="4th Floor">4th Floor</option>
                            <option value="5th Floor +">5th Floor & above</option>
                        </Select>
                    </FormControl>
                </Flex>
                <FormControl mb="6">
                    <Input required onChange={e => setPickupAddress(e.target.value)} className='anime' type="text" _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} placeholder='Pickup Address' _placeholder={{color: "gray"}} />
                </FormControl>
                <FormControl mb="6">
                    <Input required onChange={e => setDropoffAddress(e.target.value)} className='anime' type="text" _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} placeholder='Dropoff Address' _placeholder={{color: "gray"}} />
                </FormControl>
                <Flex gap={"2rem"} alignItems={"center"} mb="6" flexWrap={"wrap"}>
                    <FormControl flex={"1 1 250px"}>
                        <Input required onChange={e => setSenderName(e.target.value)} className='anime' type="text" _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} placeholder="Sender's Name" _placeholder={{color: "gray"}} />
                    </FormControl>
                    <FormControl flex={"1 1 250px"}>
                        <Input required onChange={e => setSenderPhone(e.target.value)} className='anime' type="tel" minLength={11} maxLength={11} _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} placeholder="Sender's Phone" _placeholder={{color: "gray"}} />
                    </FormControl>
                    <FormControl flex={"1 1 250px"}>
                        <Input onChange={e => setSenderEmail(e.target.value)} className='anime' type="email" _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} placeholder="Sender's Email (Optional)" _placeholder={{color: "gray"}} />
                    </FormControl>
                </Flex>
                <Flex gap={"2rem"} alignItems={"center"} mb="6" flexWrap={"wrap"}>
                    <FormControl flex={"1 1 250px"}>
                        <Input required onChange={e => setReceiverName(e.target.value)} className='anime' type="text" _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} placeholder="Receiver's Name" _placeholder={{color: "gray"}} />
                    </FormControl>
                    <FormControl flex={"1 1 250px"}>
                        <Input required onChange={e => setReceiverPhone(e.target.value)} className='anime' type="tel" minLength={11} maxLength={11} _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} placeholder="Receiver's Phone" _placeholder={{color: "gray"}} />
                    </FormControl>
                    <FormControl flex={"1 1 250px"}>
                        <Input onChange={e => setReceiverEmail(e.target.value)} className='anime' type="email" _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} placeholder="Receiver's Email (Optional)" _placeholder={{color: "gray"}} />
                    </FormControl>
                </Flex>
                <Flex gap={"2rem"} alignItems={"center"} mb="6">
                    <FormControl flex={"1 1 300px"}>
                        <Input required onChange={e => setPickupDate(e.target.value)} className='anime' type="date" _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} _placeholder={{color: "gray"}} />
                    </FormControl>
                    <FormControl flex={"1 1 300px"}>
                        <Input required onChange={e => setPickupTime(e.target.value)} className='anime' type="time" _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' borderColor={"#000"} _placeholder={{color: "gray"}} />
                    </FormControl>
                </Flex>
                <FormControl mb="6">
                    <Textarea onChange={e => setAdditionalInfo(e.target.value)} className='anime' _focus={{borderLeft: "0", borderBottom: "1px solid #123870"}} variant='flushed' rows="2" borderColor={"#000"} placeholder="Any other details?" _placeholder={{color: "gray"}} />
                </FormControl>
                <Button type='submit' w={"100%"} bg="rgba(242, 160, 7, .7)" padding="1.6rem 0" fontSize='14px' transition="250ms ease" color='#121212' border="1px solid #555039" borderRadius={"0"} 
                // boxShadow={"rgb(18, 18, 18) 5px 5px"}
                >{loading ? <Spinner /> : "REQUEST TRIP"}</Button>
            </form>
            <br /><br /><br />
        </Box>

        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent margin={"0 .5rem"}>
            {/* <ModalCloseButton onClick={() => onClose()} /> */}
            <ModalBody>
                <Image width={"30%"} margin="0 auto" src={Illustration} />
                <Text mt={3} color="#030303" fontWeight={"700"} fontSize="1.2rem" textAlign={"center"}>Request sent successfully</Text>
                <Text mt={2} color="#908888" textAlign={"center"}>Your request has been received by us.  We’ll reach out to you shortly with a quote and next steps.</Text>
                <Flex mt={6} justifyContent={"center"} gap="2rem">
                <Button onClick={() => history.push("/")} padding={"1.3rem 1rem"} borderRadius="0" border="1px solid #555039" bg="rgba(242, 160, 7, .7)" mt={2} width={400} color='#121212' fontSize=".9rem" fontWeight={"700"}>Back to home</Button>
                </Flex>
                <br />
            </ModalBody>
            </ModalContent>
        </Modal>
    </Box>
  )
}

export default RequestForm