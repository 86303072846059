import "./privacy.scss";
import { useState } from "react";
import Navbar from "../../components/navbar/navbar";

const Privacy = () => {
  return (
    <div className="privacy">
      <p>
        <strong>WEHAUL247 PRIVACY POLICY</strong>
        <br />
        <br />
        Wehaul247 is dedicated to protecting your personal information and
        informing you about how we use it. This privacy policy applies to
        transactions and activities and data gathered through the Wehaul247
        Platform. Please review our privacy policy periodically as we may revise
        it without notice. Each time you use the Wehaul247 Platform or provide
        us with information, you are thereby accepting the practices described
        in this privacy policy at that time.
        <br />
        <br /> 1. DATA PRIVACY POLICY AND USAGE <br />
        <br />
        1.1 In order to operate the Wehaul247 Platform and to provide you with
        information about services that may be of interest to you, we may
        collect “personal information” i.e. information that could be used to
        contact you directly (without using the Wehaul247 Platform) such as full
        name, phone number, or email address. You represent and warrant that
        YOU have the authority to provide US with any such contact information.
        <br /> 1.2 We use your email address and other personal information to
        help us efficiently operate the Wehaul247 Platform, to contact you in
        connection with your transactions and other activities on the Wehaul247
        Platform (including, but not limited to, confirmation emails, or
        important news that could affect your relationship with Wehaul247), to
        forward trip information to you from other Users, to forward trip
        information from you to our drivers, and to contact you and others to
        suggest potential matches.
        <br />
        1.3 We use your contact information to find and connect you with drivers
        or users. To operate the Wehaul247 Platform, including processing your
        transactions and supporting your activities on the Wehaul247 Platform,
        we may share Your personal information with our agents, representatives,
        contractors and service providers so they can provide you with support
        services such as support services, customer relationship management
        services, and order fulfillment. We require these entities not to use
        your information for any other purpose.
        <br />
        1.4 By subscribing to the services offered on the Wehaul247 Platform, or
        electing to receive communications (such as emails or material by mail)
        or electing to participate in promotions or other programs (such as
        discount or rewards programs), you consent to use of your provided
        information in these promotions. Any third party with whom we are
        allowed to share your personal information is authorized to use your
        personal information in accordance with our contractual arrangements
        with such third parties and in accordance with their own privacy
        policies, over which we have no control, and you agree that We are not
        responsible or liable for any of their actions or omissions. Those who
        contact you will need to be instructed directly by you regarding your
        preferences for the use of your personal information by them.
        <br />
        <br />
        2. HOW TO EDIT YOUR INFORMATION <br />
        <br />
        Wehaul247 provides you with the ability to access and edit your personal
        information. To update your personal info, click profile in the
        Wehaul247 menu. There you can view, update and correct your account
        information. Our databases automatically update any personal information
        you edit in your profile, or that you request we edit. Information
        transmitted through boards, chats, polls, or through any other means
        remain in our databases and become the property of Wehaul247 upon
        submission. Keep this in mind if you decide to communicate personal
        information through any of these means.
        <br />
        <br />
        3. INFORMATION RETENTION
        <br />
        <br />
        To preserve the integrity of our databases, standard procedure calls for
        us to retain information submitted by members for an indefinite length
        of time. Wehaul247 understands your submissions as consent to store all
        your information in one place for this indefinite length of time, if we
        so wish. If required by law, we will nullify member information by
        erasing it from our database. We will also respond to written member
        requests to nullify account information. Also, by using the Wehaul247
        Platform, you do hereby represent and warrant that You understand and
        agree that all information submitted by You through the Wehaul247
        Platform or otherwise to Wehaul247 becomes the property of Wehaul247 and
        may be used in the sole discretion of Wehaul247 in accordance with this
        Privacy Policy and the Terms of Use.
        <br />
        <br />
        4. CHOICE/OPT-OUT
        <br />
        <br />
        Wehaul247 provides Users the opportunity to opt-out of receiving
        communications from us and our partners at the point where we request
        information about the visitor. Wehaul247 gives Users the option to
        remove their information from our database, to not receive future
        communications or to no longer receive our service.
        <br />
        <br />
        5. SPECIAL CASES IN WHICH WE SHARE PERSONAL INFORMATION
        <br />
        <br />
        Your information provided to us may be passed on to a third party in the
        event of a transfer of ownership or assets, or a bankruptcy. We may also
        disclose your information when we determine that such disclosure is
        necessary to comply with applicable law, to cooperate with law
        enforcement or to protect the interests or safety of Wehaul247 or other
        visitors to the Wehaul247 Platform. We also may disclose your personal
        information to any of our subsidiary and parent companies and
        businesses, and other affiliated legal entities and businesses with who
        we are under common corporate control. Whenever personal information is
        disclosed under this paragraph, we may also disclose other information
        along with it, on a non-anonymous basis. All of our parent, subsidiary
        and affiliated legal entities and businesses that receive Your personal
        information or non-anonymous demographic information from us will comply
        with the terms of this privacy policy with respect to their use and
        disclosure of such information.
        <br />
        <br />
        6. OUR SECURITY PRECAUTIONS
        <br />
        <br />
        Your Wehaul247 Profile is password-protected so that only you and
        authorized Wehaul247 employees have access to your account information.
        Wehaul247 staff will never reach out to you and ask for any personal
        account information, including your password. If you share a computer or
        phone, you should sign out of your Wehaul247 account and close the
        browser window or the app before someone else logs on. This will help
        protect your information entered on public terminals from disclosure to
        third parties. Wehaul247 makes every effort to ensure that your
        information is secure on its system. Wehaul247 has staff dedicated to
        maintaining our privacy policy as set forth herein and other privacy
        initiatives, periodically reviewing Web security and making sure that
        every Wehaul247 employee is aware of our security practices.
        Unfortunately, no data transmission over the Internet can be guaranteed
        to be 100% secure. As a result, Wehaul247 cannot guarantee the security
        of any information you transmit to us, and you do so at your own risk.
        If you have any further questions on this issue, refer to Wehaul247
        Terms of Use. Wehaul247 expressly disclaims any liability that may arise
        should any other individuals obtain the information you submit to the
        Wehaul247 Platform. Wehaul247 has security measures in place to protect
        against the loss, misuse and alteration of the information under our
        control. Your information may be transferred to and maintained on
        computer networks which may be located outside of the country or other
        governmental jurisdiction in which you reside, and the country or
        jurisdiction in which these computer networks are located may not have
        privacy laws as protective as the laws in your country or jurisdiction.
        The Wehaul247 Platform may contain links to other web sites. We are not
        responsible for the privacy practices of other web sites. We encourage
        our Users to be aware when they leave the Wehaul247 Platform to read the
        privacy statements of each and every web site that collects personally
        identifiable information. This Privacy Policy applies solely to
        information collected by the Wehaul247 Platform.
        <br />
        <br />
        7. CHANGING OUR PRIVACY POLICY FOR PREVIOUSLY GATHERED INFORMATION
        <br />
        <br />
        If at any point we decide to use particular personally identifiable
        information in a manner materially different from that stated at the
        time it was collected, we will notify Users (drivers and customers) by
        way of an email or by providing 30 days’ notice on the Wehaul247
        Platform. We also encourage you to review this privacy policy
        periodically.
        <br />
        <br />
        8. WARRANTY/AGREEMENT
        <br />
        <br />
        By using the Wehaul247 Platform, you do hereby represent and warrant
        that you have read, understand and agree to all terms of Agreement and
        the Data Privacy Policy. Each time you use the Wehaul247 Platform, you
        agree to all terms set forth in the Terms of Use and this Privacy Policy
        and any other policies published by Wehaul247 on the Wehaul247 Platform.
        Wehaul247 will continue to have the right to change our privacy policy
        and practices, and how we use your personally identifiable information,
        without notice, as described in herein, provided that such changes shall
        only apply to information gathered on or after the date of the change.
      </p>
    </div>
  );
};

export default Privacy;
