import "./navbar.scss";
import Logo from "./wehaulogo.png";
import { Link } from "react-router-dom";
import { useState } from "react";
import Reglog from "../../pages/reglogModal/reglog";
import Sidebar from "../sidebar/sidebar";
import { Box, Button, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, FormControl, Heading, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl } from "../../config";
import { CheckCircleIcon } from "@chakra-ui/icons";



const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expansible, setExpansible] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [reqId, setReqId] = useState("");
  const [historyx, setHistoryx] = useState(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();


  const openNav = () => {
    setNavOpen(true);
  };
  const closeNav = () => {
    setNavOpen(false);
  };



  const trackRequest = async () => {
    setLoading2(true);

            try {
                const res = await axios.get(`${baseUrl}/tracking/track/${reqId}`)
                console.log("tracking history", res?.data?.data)
                setHistoryx(res?.data?.data)
                onClose()
                onOpen2();
            } catch(error) {
                console.log(error?.response?.data?.message)
                toast.error(error?.response?.data?.message)
            }
            finally {
              setLoading2(false);
            }
        }


  return (
      <>
      <HStack height={"90px"} padding="1rem 2rem">
        <Flex gap={"2rem"} alignItems={"center"}>
          <Image cursor={"pointer"} mr="2rem" w={{base: "20%", md: "9%"}} src={Logo} />
          <Link to="/"><Text className="right" cursor={"pointer"} color={"#F2A007"} fontSize={".9rem"} fontWeight={"600"}>Home</Text></Link>
          <Link to="/trackDelivery"><Text className="right" whiteSpace={"nowrap"} cursor={"pointer"} fontSize={".9rem"} fontWeight={"600"}>Track Delivery</Text></Link>
          <a href="https://wehaul247movers.com" target="_blank" rel="noopener noreferrer"><Text className="right" cursor={"pointer"} fontSize={".9rem"} fontWeight={"600"}>Wehaul247 Movers</Text></a>
          <a href="https://blog.wehaul247.com" target="_blank" rel="noopener noreferrer"><Text className="right" cursor={"pointer"} fontSize={".9rem"} fontWeight={"600"}>Blog</Text></a>
        </Flex>

        <Flex fontSize={".9rem"} gap={"2rem"} alignItems={"center"}>
          {/* <Text className="right" whiteSpace={"nowrap"} fontWeight={"600"}>Call us at:  01-888-6060</Text> */}
          <a href="tel:+2349160000687"><Text className="right" whiteSpace={"nowrap"} fontWeight={"600"}>Call us at:  0916-0000-687</Text></a>
          <Link className="right" onClick={() => setExpansible(false)} to="/driver" style={{ textDecoration: "none" }}><Button bg="#F2D399" fontSize={".9rem"} fontWeight="600">Become a Driver</Button></Link>
          <Text className="right" cursor={"pointer"} fontWeight={"600"} onClick={() => setShowModal(true)}>Login/Register</Text>
        </Flex>

        <div className="bar">
          {navOpen ? (
              <i onClick={closeNav} class="fas fa-times"></i>
            ) : (
              <i onClick={openNav} class="fas fa-bars"></i>
          )}
        </div>
      </HStack>



     

      {showModal && <Reglog setRegLogModal={setShowModal} />}
      
      <Sidebar
        setShowModal={setShowModal}
        setNavOpen={setNavOpen}
        navOpen={navOpen}
        track={onOpen}
      />


        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent margin={"0 .5rem"}>
            <ModalHeader>Track Request</ModalHeader>
            <ModalCloseButton _focus={{boxShadow: "none"}} />
            <ModalBody>
                <FormControl>
                    <Input onChange={e => setReqId(e.target.value.trim())} textTransform="uppercase" placeholder="Enter Request ID" />
                </FormControl>
            </ModalBody>

            <ModalFooter>
                <Button variant='ghost' mr={3} onClick={onClose}>
                Close
                </Button>
                <Button type="sumbit" colorScheme='blue' w={130} onClick={trackRequest}>{loading2 ? <Spinner /> : "Find Request"}</Button>
            </ModalFooter>
            </ModalContent>
        </Modal>

        <Drawer
            isOpen={isOpen2}
            placement='right'
            onClose={onClose2}
            size="md"
        >
            <DrawerOverlay />
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader color="#344055">Delivery Status History</DrawerHeader>

            <DrawerBody>
                <Text pb={3} fontSize='24px' textTransform='uppercase' color="gray.800" fontWeight='600'>{historyx?.trackTitle}</Text>
                <Divider />
                <Flex mt={5} direction="row" alignItems="center">
                  <Heading color="gray.800" size="md" mr={3}>{historyx?.requestId}</Heading>
                  <Button size="xs" backgroundColor="#2a8960" color="#fff">Copy Request ID</Button>
                </Flex>
                <Text pb={4} mt={3} textAlign="left" lineHeight={1} size="xs" color="gray.500">Please copy and keep this code to revisit your request and track progress.</Text>
                <Divider />

                <Box pt={5}>
                  {historyx?.trackStages.map(({stageTitle}, index) => {
                    return (
                      <Box key={index}>
                        <Flex direction='row'>
                          <CheckCircleIcon marginTop="2px" mr={3} fontSize={20} color='green' />
                          <Text mb={10}>{stageTitle}</Text>
                        </Flex>
                      </Box>
                      
                      
                    )
                  })}
                </Box>
                
            </DrawerBody>
            </DrawerContent>
        </Drawer>
        </>
    // </div>
  );
};

export default Navbar;
