import React, {useState} from "react";
import "./liveTracking.scss";
import { withGoogleMap, GoogleMap, Marker, DirectionsRenderer } from "react-google-maps";
import { useEffect } from "react";
import axios from "axios";
import { Box, Button, Text } from "@chakra-ui/react";
// import {baseUrl} from "../../config.json";

const config = {
  headers: {
     "Authorization": `Bearer ${localStorage.WEHAUL_CUSTOMER_TOKEN}`
     }
}

const LiveTracking = ({reqID, mapData, openModal}) => {
    
console.log(mapData)

  const MyMapComponent = withGoogleMap((props) => (
    <GoogleMap defaultZoom={18} defaultCenter={{ lat:mapData?.trackingDetails?.location[1], lng: mapData?.trackingDetails?.location[0] }}>
      {props.isMarkerShown && <Marker labelClass="trt" labelStyle={{color: "red !important"}} defaultTitle="hgchgc" 
      // label={acceptedBy?.name}
       position={{  lat: mapData?.trackingDetails?.location[1], lng: mapData?.trackingDetails?.location[0]  }} />}
      {/* {props.isMarkerShown && (
        locations?.map(({trackingDetails, acceptedBy}, index) => {
          return (
            <Marker labelClass="trt" labelStyle={{color: "red !important"}} defaultTitle="hgchgc" label={acceptedBy?.name} key={index} position={{  lat:6.657457, lng: 3.323225  }} />
          )
        })
      )} */}
    </GoogleMap>
  ));

        // const DirectionsService = new google.maps.DirectionsService();

        // DirectionsService.route({
        //     origin: new google.maps.LatLng(41.8507300, -87.6512600),
        //     destination: new google.maps.LatLng(41.8525800, -87.6514100),
        //     travelMode: new google.maps.TravelMode.DRIVING,
        //   }, (result, status) => {
        //     if (status === google.maps.DirectionsStatus.OK) {
        //       this.setState({
        //         directions: result,
        //       });
        //     } else {
        //       console.error(`error fetching directions ${result}`);
        //     }
        //   });

  return (
    <div className="live-tracking" style={{flex: 1}}>

      {/* <div className="top-head">
        <i className="fas fa-home"></i>
        <p className="top-head-left">Live Tracking</p>
      </div>
      <br />
      <br /> */}

      {mapData?.tripStatus === "pending" ? 
      <Box textAlign={"center"} mt={10}>
        <Text>Trip has not been started by the driver</Text>
        <Text>Map would be displayed when trip is started</Text>
      </Box>
      :
        <div style={{position: "relative"}} className="map-area">
          <MyMapComponent isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `500px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          />
          {!localStorage.WHSUTK && 
          <>
          <Box textAlign={"center"} position={"absolute"} zIndex="4" top="50%" left={"50%"} transform="translate(-50%, -50%)">
            <Text fontWeight={"500"} mb="2">Login or Sign up to track trip on map.</Text>
            <Button onClick={() => openModal()} bg="#113E82" color="#fff">Login</Button>
          </Box>
          <Box filter="blur(8px)" position={"absolute"} top="0" bottom="0" right="0" left="0" bg="rgba(255,255,255, .85)"></Box>
          </>
          }
      </div>}

    </div>
  );
};

export default LiveTracking;
