import React, { useState, useEffect} from "react";
import ButtonLoader from "../../components/buttonLoader/buttonLoader";
import "./onboarding.scss";
// import { useQuery } from "react-query";
import { toast } from "react-toastify";
import axios from "axios";

import {getOtherRoutes, getAllVehicles} from '../../services/reqs';
import Online from "./online/online.jsx";
import Offline from "./offline/offline";
import Navbar from "../../components/navbar/navbar";
const baseUrl = "https://app.wehaul247.com/api/v1"
// import { baseUrl, herokuBaseUrl } from '../../../config.json';

const Onboarding = ({setShowModal}) => {

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const config = {
      headers: {
         "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyMDU1NjViODAxYjM2MDAxMzQ5YzUwYSIsIm5hbWUiOiJMaWFtIE51c3NpZSIsInJvbGUiOiJkcml2ZXIiLCJpYXQiOjE2NDYxNDQ1MDAsImV4cCI6MTY0NjIzMDkwMH0.yECbB91b9P_DYRmxmiGNcZDWrwQuKpgHYMh2vzn5Cbo`
         }
   }


  const [routes, setRoutes] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  const [page, setPage] = useState("online");
 
  
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);

  

  return (
    <div className="onboarding">
      <Navbar />
        <p className="title">ONBOARDING</p>
        {/* <div onClick={() => setShowModal(false)} className="back"><i className="fas fa-arrow-left"></i>Back</div> */}

    <div className="ttt">
        <ul className="nav">
            <li onClick={() => setPage("online")} className={page === "online" ? "active" : null}>Online</li>
            <li onClick={() => setPage("offline")} className={page === "offline" ? "active" : null}>Offline</li>
        </ul>  
    </div>

    {page === "online" && <Online />}
    {page === "offline" && <Offline />}
        

      

      
    </div>
  );
};

export default Onboarding;
