import { ArrowDownIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { Box, Button, Divider, Flex, FormControl, FormLabel, Heading, HStack, Image, Input, InputGroup, InputRightElement, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LiveTracking from "./live-tracking/liveTracking";
import Goo from "../../assets/google-download.png";
import App from "../../assets/apple-download.png";
// import { baseUrl } from '../../config.json';
import Dummy from './live-tracking/dummy.png';
import Banner from './live-tracking/banner.jpg';

const TrackDelivery = (props) => {

    const [loading, setLoading] = useState(true)
    const [loading2, setLoading2] = useState(true)
    const [historyx, setHistoryx] = useState([])

    const query = new URLSearchParams(props.location.search);
    const reqID = query.get('reqID')
    console.log("location", reqID)

    const [locations, setLocations] = useState([6.657457, 3.323225]);
    const [mapData, setMapData] = useState(null);

    useEffect(() => {
      axios.get(`https://app.wehaul247.com/api/v1/tracking/driverLocation/${reqID}`)
      // .then(res => console.log("trackings found", res?.data?.data))
      .then(res => setMapData(res?.data?.data))
      .then(res => setLoading2(false))
      .catch(err => console.log(err))
    }, [])

    const trackRequest = async () => {
    
                try {
                    const res = await axios.get(`https://app.wehaul247.com/api/v1/tracking/track/${reqID}`)
                    console.log("tracking history", res?.data?.data)
                    setHistoryx(res?.data?.data)
                    // onClose()
                    // onOpen2();
                } catch(error) {
                    console.log(error?.response?.data?.message)
                    toast.error(error?.response?.data?.message)
                }
                finally {
                  setLoading(false);
                }
    }

    useEffect(() => {
        trackRequest()
    }, [])




    const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [email2, setEmail2] = useState("");
  const [password2, setPassword2] = useState("");
  const [cpassword2, setCpassword2] = useState("");

    const { isOpen, onOpen, onClose } = useDisclosure();

    const [isLogin, setIsLogin] = useState(true);
  
    const [loading1, setLoading1] = useState(false);
    const [loading3, setLoading3] = useState(false);

    const [fetching, setFetching] = useState(false);

    const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);


  const formData = {
    email,
    password
}

const login = async (e) => {
    e.preventDefault();
    setLoading1(true)
     try {
        const { data } = await axios.post(`https://app.wehaul247.com/api/v1/auth/customer/login`, formData);
        console.log(data, data.token)
        setLoading1(false)
        localStorage.setItem("WHSUTK", data.token);
        onClose()
        // window.location.reload();
     } catch (error) {
        console.log(error?.response?.data?.message)
        toast.error(error?.response?.data?.message, {
           position: toast.POSITION.TOP_CENTER
        })
        setLoading1(false)
     }
  }

  const formData2 = {
    "name": fullname,
    "phoneNumber": phone,
    "whatsAppNumber" : phone,
    "country": "nigeria",
    "email": email2,
    "password": password2,
    "userClass": 'customer'
}

const signup = async (e) => {
  e.preventDefault();
  setLoading3(true)
   try {
      const { data } = await axios.post(`https://app.wehaul247.com/api/v1/auth/sign-up`, formData2);
      // console.log(data, data.token)
      setLoading3(false)
      localStorage.setItem("WHSUTK", data.token);
      onClose()
      // window.location.reload();
   } catch (error) {
      console.log(error?.response?.data?.message)
      toast.error(error?.response?.data?.message, {
         position: toast.POSITION.TOP_CENTER
      })
      setLoading3(false)
   }
}

    return (
        <Box paddingLeft={{base: ".7rem", md:"2rem"}} paddingRight={{base: ".7rem", md:"2rem"}}>
          

            {/* <Box mb={4} height={"90px"} backgroundImage={Banner} backgroundSize="contain"></Box> */}

            <Box mb={3} display={{base: "block", md: "none"}} height={"80px"} position={"relative"} backgroundImage={Dummy} backgroundSize="cover" borderRadius={"8px"} overflow="hidden" flex={1}>
                <Flex justifyContent={"right"} position={"absolute"} top="0" left="0" bottom="0" right="0" bg="rgba(0,0,0,.8)">
                  <a href={`https://customer.wehaul247.com/viewTripDetails/${historyx?.tripId}/${historyx?.requestId}`} target="_blank" rel="noopener noreferrer"><Button mt="5" mr="4" width={"100"} bg="#113E82" color="#fff" fontWeight={"500"}>Click to Track and View Trip Details</Button></a>
                </Flex>
            </Box>


            <Flex justifyContent={"space-around"} gap="1rem" wrap={"wrap"} margin={"0 auto"}>

           
            {/* <FormControl>
                <Input value={reqID} contentEditable="false" textTransform={"uppercase"} />
            </FormControl> */}

            

            <Box maxW="600px" padding={{base: "1rem", md:"2rem"}} mb={3} height={"420px"} borderRadius={"8px"} border="1px solid lightgray">
                {loading ? <Flex><Spinner /></Flex> : 
                <>
                    <Text pb={3} fontSize='24px' textTransform='uppercase' color="gray.800" fontWeight='600'>{historyx?.trackTitle}</Text>
                <Divider />
                {/* <Flex mt={5} direction="row" alignItems="center" wrap="wrap">
                  <Heading color="gray.800" size="md" mr={3}>{historyx?.requestId}</Heading>
                  <Button size="xs" backgroundColor="#2a8960" color="#fff">Copy Request ID</Button>
                </Flex> */}
                {/* <Text pb={4} mt={3} textAlign="left" lineHeight={1} size="xs" color="gray.500">You can also copy and keep this code to revisit your request and track progress.</Text> */}
                {/* <Divider /> */}

                <Box pt={5}>
                  {historyx?.trackStages?.map(({stageTitle, date}, index) => {
                    return (
                        <Flex bg="rgba(0, 128, 0, .15)" padding={".5rem"} borderRadius=".4rem" mb={10} key={index} alignItems={"center"} justifyContent="space-between">
                          <Flex>
                            <CheckCircleIcon marginTop="2px" mr={3} fontSize={20} color='green' />
                            <Text whiteSpace={"nowrap"}>{stageTitle}</Text>
                          </Flex>
                          <Text textAlign={"right"} maxWidth="150px" ml={8} fontSize={".75rem"}>{moment(date).format('MMMM Do YYYY, h:mm:ss a')}</Text>
                          {index < historyx?.trackStages?.length - 1 && <ArrowDownIcon position={"absolute"} bottom="-70%" />}
                        </Flex>
                    )
                  })}
                </Box>
                </>
                }
            </Box>

            <Box position={"relative"} display={{base: "none", md: "flex"}} backgroundImage={Dummy} backgroundSize="cover" borderRadius={"8px"} overflow="hidden" flex={1}>
                <Box position={"absolute"} top="0" left="0" bottom="0" right="0" bg="rgba(0,0,0,.8)">
                  <a href={`https://customer.wehaul247.com/viewTripDetails/${historyx?.tripId}/${historyx?.requestId}`} target="_blank" rel="noopener noreferrer"><Button width={"100"} margin="20% 0 0 32%" bg="#113E82" color="#fff" fontWeight={"500"}>Click to Track and View Trip Details</Button></a>
                </Box>
            </Box>

            {/* {!loading2 && <LiveTracking reqID={reqID} mapData={mapData} openModal={onOpen} />} */}
                
            </Flex>

            <Box bg="rgba(17, 62, 130, .2)">
               <Text display={{base: "none", md: "block"}} marginTop={"1rem"} pt={2} ml="3rem" textAlign={"center"} whiteSpace={"nowrap"} fontWeight={"700"} fontSize="1rem">Download Our Mobile App</Text>
                <Flex minW={"300px"} flex={"1"} flexWrap="wrap" gap={"1rem"} justifyContent={{base: "center"}} alignItems={"center"} padding={{base: "1rem", md: "1rem"}} pl={{base: "0", lg: "6rem"}} pt={{base: "1rem"}} pb={{base: "1rem"}}>
                    
                    <Image onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=com.wehaul.customer&hl=en&gl=US"} cursor={"pointer"} width={{base: "40%", md: "12%"}} src={Goo} />
                    <Image onClick={() => window.location.href = "https://apps.apple.com/us/app/wehaul/id1618324644"} cursor={"pointer"} width={{base: "40%", md: "12%"}} src={App} />
                </Flex>
            </Box>
           





            <Modal isCentered closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg="#000000e9" />
                <ModalContent>
                {isLogin ? (
            <>
              {/* <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" /> */}
              <ModalHeader fontSize="24px">Sign In</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Sign in to your wehaul247 account.
              </Text>
              {/* <ModalCloseButton /> */}

              <form onSubmit={login}>
                <ModalBody>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <HStack>
                    <Text fontSize="14px">Don't Have an Account ? </Text>
                    <Text
                      onClick={() => setIsLogin(false)}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Sign Up
                    </Text>
                  </HStack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button type="submit" colorScheme="green">
                    {loading1 ? <Spinner /> : "Sign In"}
                  </Button>
                </ModalFooter>
              </form>
            </>
          ) : (
            <>
              {/* <img style={{width: "30%", margin: "0 auto", paddingTop: ".5rem"}} src={Logo} alt="get_codes_logo" /> */}
              <ModalHeader fontSize="24px">Create Account</ModalHeader>
              <Text mt={-5} ml={6} fontSize="13px" color="gray.400">
                Sign up for your wehaul247 account.
              </Text>
              {/* <ModalCloseButton /> */}

              <form onSubmit={signup}>
                <ModalBody>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">Full Name</FormLabel>
                    <Input
                      onChange={(e) => setFullname(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter name"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">
                      Email Address
                    </FormLabel>
                    <Input
                      onChange={(e) => setEmail2(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="email"
                      fontSize="md"
                      placeholder="Enter email"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">
                      Phone Number
                    </FormLabel>
                    <Input
                      onChange={(e) => setPhone(e.target.value)}
                      _focus={{ boxShadow: "none" }}
                      size="lg"
                      type="text"
                      fontSize="md"
                      placeholder="Enter phone"
                      color="gray.500"
                      _placeholder={{ color: "#718096" }}
                    />
                  </FormControl>
                  <FormControl mb={3}>
                    <FormLabel transform="translateY(8px)">Password</FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setPassword2(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>
                  {/* <FormControl mb={4}>
                    <FormLabel transform="translateY(8px)">
                      Confirm Password
                    </FormLabel>
                    <InputGroup>
                      <Input
                        onChange={(e) => setCpassword2(e.target.value)}
                        _focus={{ boxShadow: "none" }}
                        size="lg"
                        type={show ? "text" : "password"}
                        fontSize="md"
                        placeholder="Re-Enter password"
                        color="gray.500"
                        _placeholder={{ color: "#718096" }}
                      />
                      <InputRightElement marginTop="5px" width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? "Hide" : "Show"}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl> */}

                  <HStack>
                    <Text fontSize="14px">Have an Account Already ? </Text>
                    <Text
                      onClick={() => setIsLogin(true)}
                      cursor="pointer"
                      fontSize="14px"
                      fontWeight="600"
                      color="#009942"
                    >
                      Sign In
                    </Text>
                  </HStack>
                </ModalBody>

                <ModalFooter>
                  <Button variant="ghost" mr={3} onClick={onClose}>
                    Close
                  </Button>
                  <Button type="submit" colorScheme="green">
                    {loading3 ? <Spinner /> : "Sign Up"}
                  </Button>
                </ModalFooter>
              </form>
            </>
          )}
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default TrackDelivery;