import './driversx.scss';
import Img from '../../../assets/bike-landing.jpg';

const Driversx = () => {
    return (
        <div style={{backgroundImage: `url(${Img})`}} className="driversx">
            <div className="boxx">
                <div className="text">
                    <h1 className="top">Partner - Bike Logistics</h1>
                    <h2 className="title">Drive & make more Money moving items from point A to B</h2>
                    <h2 className="para">Join our pool of WEHAUL drivers and respond to pickup requests, set your own prices and work at your own convenience.</h2>
                </div>
                <button onClick={() => window.location.href = "https://driver.wehaul247.com/signup"}>Register Now<i className="fas fa-arrow-right"></i></button>
            </div>
        </div>
    )
}

export default Driversx;