import './vehicles.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useState } from 'react';
import { Box, Divider, Flex, HStack, Image, Stack, Text } from '@chakra-ui/react';
import Wi from "./wicon.png";
import Goo from "../../assets/google-download.png";
import App from "../../assets/apple-download.png";
import Vehiclesx from "./vehicles.png";
import { ArrowUpIcon } from '@chakra-ui/icons';

const Vehicles = () => {

    console.log(navigator.userAgent)

    // const handleApps = () => {
    //     if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
    //       window.location.href = "https://play.google.com/store/apps/details?id=com.wehaul247apps.driver";
    //     } else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
    //       window.location.href = 'http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8';
    //     } else return
    //   }
    // const handleApps2 = () => {
    //     if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
    //       window.location.href = "https://play.google.com/store/apps/details?id=com.wehaul.customer";
    //     } else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
    //       window.location.href = 'http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8';
    //     } else return
    //   }

    return (
        <Box>

       
        <Flex  flexWrap="wrap" overflow={"hidden"}>
            <Flex bg="#123870" color={"#fff"} minW={"300px"} flex={"1"} justifyContent={{base: "center", md:"flex-end"}} flexWrap="wrap" gap={"1rem"} alignItems={"center"} pr={{base: "0", lg: "3.5rem"}} pt={{base: "1rem"}} pb={{base: "1rem"}}>
                <Text whiteSpace={"nowrap"} fontWeight={"700"} fontSize="1rem">Request to move your item via Whatsapp &#62;</Text>
                <Image onClick={() => window.open('https://wa.me/2349160000687', "_blank") || window.location.replace('https://wa.me/2349160000687')} cursor={"pointer"}  width={{base: "40%", md: "25%"}} src={Wi} />
            </Flex>
            <Divider orientation='vertical' />
            <Flex bg="#123870" color={"#fff"} minW={"300px"} flex={"1"} flexWrap="wrap" justifyContent={{base: "center", md:"flex-start"}} gap={"1rem"} alignItems={"center"} padding={{base: "1rem", md: "1rem"}} pl={{base: "0", lg: "3rem"}} pt={{base: "1rem"}} pb={{base: "1rem"}}>
                <Text whiteSpace={"nowrap"} fontWeight={"700"} fontSize="1rem">Or Download Our Mobile App &#62;</Text>
                <Image onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=com.wehaul.customer&hl=en&gl=US"} cursor={"pointer"} width={{base: "37%", md: "20%"}} src={Goo} />
                <Image onClick={() => window.location.href = "https://apps.apple.com/us/app/wehaul/id1618324644"} cursor={"pointer"} width={{base: "37%", md: "20%"}} src={App} />
            </Flex>
        </Flex>

        <Stack maxW={"1200px"} ml="auto" mr="auto" mt={{base: "2rem", md: "4rem"}} mb={{base: "2rem", md: "4rem"}} alignItems="center" justifyContent="space-between" direction={{base: "column", md: "row"}}>
            <Text fontSize={{base: "1.2rem", md: "1.8rem"}} textAlign={{base: "center", md: "left"}} maxW={"460px"} lineHeight="34px">Rent or Hire Cars, Vans & Trucks to Move any item & goods from one place to another</Text>
            <Image src={Vehiclesx} width={{base: "92%", md: "40%"}} />
        </Stack>

        <Flex maxW={"1200px"} padding={{base: ".8rem", md: "0"}} margin="0 auto 2rem auto" justifyContent={"space-between"} zIndex={100000} gap={"1rem"} flexWrap="wrap" alignItems="center">
            <Box height={"330px"} flexGrow="1" borderRadius="8px" minW="300px" bg="#F2D399" padding={"1.8rem"}>
                <Box height={"40px"} width="40px" borderRadius={"4px"} bg="#000">
                    <ArrowUpIcon color={"#F2D399"} fontSize="2.2rem" transform={"rotate(45deg)"} ml=".5rem" mt="-.2rem" />
                </Box>
                <Text mt="1rem" maxW={"200px"} lineHeight="24px" fontWeight={"600"} fontSize="1.2rem">Relocate your Apartment or Office</Text>
                <Text mt={8} maxW={"330px"}>Relocations have never been easier. Move your entire furniture to a new location or single large items like beds, couches, generator, fridge and much more.</Text>
            </Box>
            <Box height={"330px"} flexGrow="1" borderRadius="8px" minW="300px" bg="#F2D399" padding={"1.8rem"}>
                <Box height={"40px"} width="40px" borderRadius={"4px"} bg="#000">
                    <ArrowUpIcon color={"#F2D399"} fontSize="2.2rem" transform={"rotate(45deg)"} ml=".5rem" mt="-.2rem" />
                </Box>
                <Text mt="1rem" maxW={"200px"} lineHeight="24px" fontWeight={"600"} fontSize="1.2rem">Move your Mini or Large Item</Text>
                <Text mt={8} maxW={"330px"}>Rent or Hire Cars, Vans & Trucks to Move any item & goods from one place to another.</Text>
            </Box>
            <Box height={"330px"} flexGrow="1" borderRadius="8px" minW="300px" bg="#F2D399" padding={"1.8rem"}>
                <Box height={"40px"} width="40px" borderRadius={"4px"} bg="#000">
                    <ArrowUpIcon color={"#F2D399"} fontSize="2.2rem" transform={"rotate(45deg)"} ml=".5rem" mt="-.2rem" />
                </Box>
                <Text mt="1rem" maxW={"200px"} lineHeight="24px" fontWeight={"600"} fontSize="1.2rem">Business and Partner Delivery</Text>
                <Text mt={8} maxW={"330px"}>Rent or Hire Cars, Vans & Trucks to Move any item & goods from one place to another.</Text>
            </Box>
        </Flex>

        <Flex mb={10} maxW={"1200px"} margin="0 auto 2rem auto" flexWrap="wrap" overflow={"hidden"}>
            <Flex borderBottom="1px solid" borderBottomColor={{base: "#F2A007", md: "#FFF"}} borderRight="1px solid" borderRightColor={{base: "#FFF", md: "#F2A007"}}  minW={"300px"} justifyContent={{base: "center"}} flex={"1"} flexWrap="wrap" gap={"1rem"} alignItems={"center"} pr={{base: "0", lg: "3.5rem"}} pt={{base: "1rem"}} pb={{base: "1rem"}}>
                <Text whiteSpace={"nowrap"} fontWeight={"700"} fontSize="1rem">Request to move your item via Whatsapp</Text>
                <Image onClick={() => window.open('https://wa.me/2349160000687', "_blank") || window.location.replace('https://wa.me/2349160000687')} cursor={"pointer"}  width={{base: "50%", md: "25%"}} src={Wi} />
            </Flex>
            <Flex minW={"300px"} flex={"1"} flexWrap="wrap" gap={"1rem"} justifyContent={{base: "center"}} alignItems={"center"} padding={{base: "1rem", md: "1rem"}} pl={{base: "0", lg: "6rem"}} pt={{base: "1rem"}} pb={{base: "1rem"}}>
                <Text whiteSpace={"nowrap"} fontWeight={"700"} fontSize="1rem">Or Download Our Mobile App</Text>
                <Image onClick={() => window.location.href = "https://play.google.com/store/apps/details?id=com.wehaul.customer&hl=en&gl=US"} cursor={"pointer"} width={{base: "40%", md: "19%"}} src={Goo} />
                <Image onClick={() => window.location.href = "https://apps.apple.com/us/app/wehaul/id1618324644"} cursor={"pointer"} width={{base: "40%", md: "19%"}} src={App} />
            </Flex>
        </Flex>


        </Box>

    )
}

export default Vehicles;