import { ArrowRightIcon, ArrowUpIcon, CalendarIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Heading, Stack, Text } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";

const Blog = () => {

    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        axios.get("https://blog.wehaul247.com/wp-json/wp/v2/posts")
        // .then(res => console.log(res?.data))
        .then(res => setPosts(res?.data))
        .then(res => setLoading(false))
        .catch(error => console.log(error?.response?.data?.message))
    }, [])
    
    return (
        <Box
        data-aos="fade-up"
        // marginTop="100px"
        marginBottom="80px"
        // padding={["2rem 2rem", "2rem 6rem"]}
        pl={{base: "1rem", md: "4rem"}}
        pr={{base: "1rem", md: "4rem"}}
      >
        <Stack
          direction={["column", "row"]}
          marginTop="7rem"
          marginBottom="2rem"
          alignItems="center"
          justifyContent="space-between"
          gap="2rem"
        >
          <Box>
            <Heading
              marginLeft={["0px", "0px", "0px", "50px"]}
              lineHeight="40px"
              size="lg"
              className="bold"
              fontSize={["3.5em", "4em", "4em", "7rem"]}
              color="#290F44"
              opacity="0.1"
            >
              READ BLOG
            </Heading>
            {/* <Flex> */}
            <Heading
              className="bold"
              maxW="600px"
              fontWeight="600"
              fontSize="1.9rem"
              color="#0F0550"
            >
              STAY INFORMED <br /> ON OUR LATEST NEWS
            </Heading>
            {/* <Text color="#00FE98" fontSize="4rem">.</Text> */}
            {/* </Flex> */}
          </Box>
          <a href="https://blog.wehaul247.com" target="_blank" rel="noopener noreferrer"><Button
            mt={10}
            width={170}
            padding="1.5rem 3rem"
            borderRadius="5px"
            bg="#0F0550"
            _hover={{ background: "#290F44" }}
            color="#fff"
            fontSize="14px"
            // onClick={() => navigate("/blog")}
          >
            VIEW ALL POSTS
          </Button></a>
        </Stack>

        <Flex margin="5rem auto 2rem auto" justifyContent={"space-between"} zIndex={100000} gap={"1rem"} flexWrap="wrap" alignItems="center">
            {posts?.map(({date, excerpt, title, link, jetpack_featured_media_url}, index) => {
                return (
                    <Box width={{base: "100%", md: "auto"}} key={index} overflow={"hidden"} height={"385px"} flexGrow="0" borderRadius="8px" minW="350px" bg="#FFF" boxShadow="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;">
                        <Box width={"100%"} height="260px" backgroundImage={jetpack_featured_media_url} backgroundSize="cover">

                        </Box>
                        <Box padding={".4rem .7rem"}>
                        <a href={link} target="_blank" rel="noopener noreferrer"><Text fontSize={"1.1rem"} mb={2} fontWeight="500" maxW={"320px"}>{title?.rendered}</Text></a>
                            {/* <Text fontSize={".95rem"} maxW={"350px"} color="gray">{excerpt?.rendered.slice(0,42)}..</Text> */}
                            <Flex mt={8} justifyContent={"space-between"}>
                                <a href={link} target="_blank" rel="noopener noreferrer"><Flex color="#0F0550" alignItems={"center"}>
                                    <Text color={""} fontSize=".9rem" cursor={"pointer"}>Read More</Text>
                                    <ArrowRightIcon transform={"translateY(1px)"} ml={1} fontSize={".6rem"} />
                                </Flex></a>
                                
                                <Flex alignItems={"center"}>
                                    <CalendarIcon color="#333" mr={2} />
                                    <Text color="#333" fontSize=".85rem">{moment().format('MMMM Do YYYY', date)}</Text>
                                </Flex>
                            
                                
                            </Flex>
                            
                            
                        </Box>
                    </Box>
                )
            })}
        </Flex>

      </Box>
    )
}

export default Blog;