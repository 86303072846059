import React, { useState } from "react";
import PasswordChecklist from "react-password-checklist";
import "./offline.scss";
import routesx from "../../../static/temp";
import Select from "react-select";
import banksData from "./banks";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import ButtonLoader from "../../../components/buttonLoader/buttonLoader";
import Compressor from "compressorjs";
import vehiclesData from "../vehicles";
import states from "./states";
import SuccessM from "../successModal/successM";
// const baseUrl = "http://wehaultestserver-env-1.eu-west-2.elasticbeanstalk.com/api/v1";
// const baseUrl = "https://wehaul-nig.herokuapp.com/api/v1";
const baseUrl = "https://app.wehaul247.com/api/v1";

const config = {
  headers: {
    Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
  },
};

const Offline = () => {
  const [pLoader, setPLoader] = useState(false);
  const [dLoader, setDLoader] = useState(false);
  const [vLoader, setVLoader] = useState(false);
  const [aLoader, setALoader] = useState(false);
  const [d1Loader, setD1Loader] = useState(false);
  const [d2Loader, setD2Loader] = useState(false);
  const [finalLoader, setFinalLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [msg, setMsg] = useState("");
  const [resType, setResType] = useState("");

  const [brand, setBrand] = useState("");
  const [tempModel, setTempModel] = useState([]);
  const [model, setModel] = useState([]);
  const [smartPhone, setSmartPhone] = useState(null);
  const [downloaded, setDownloaded] = useState(null);
  const [vehicleTypex, setVehicleTypex] = useState("");
  const [vehicleLoc, setVehicleLoc] = useState("");

  const [role, setRole] = useState("");
  const [number, setNumber] = useState("");
  const [wNumber, setWNumber] = useState("");
  const [emailr, setEmailr] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [refCode, setRefCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loadingr, setLoadingr] = useState(false);
  const [fullname, setFullname] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");

  const formData = {
    name: fullname,
    phoneNumber: number,
    whatsAppNumber: wNumber,
    email: emailr,
    referralCode: refCode,
    password: password,
    userClass: "driver",
  };
  const formData2 = {
    name: fullname,
    phoneNumber: number,
    whatsAppNumber: wNumber,
    email: emailr,
    password: password,
    userClass: "driver",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const verify = {
      email,
      userClass: "driver",
    };
    if (password === confirmPassword) {
      if (refCode.length > 1) {
        setLoading(true);
        try {
          const { data } = await axios.post(
            `${baseUrl}/auth/sign-up`,
            formData
          );
          setLoading(false);
          toast.success(data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          localStorage.setItem("ONBOARDING_TOKEN", data?.token);
        } catch (error) {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        setLoading(true);
        try {
          const { data } = await axios.post(
            `${baseUrl}/auth/sign-up`,
            formData2
          );
          setLoading(false);
          toast.success(data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          localStorage.setItem("ONBOARDING_TOKEN", data?.token);
        } catch (error) {
          setLoading(false);
          toast.error(error?.response?.data?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      }
    } else {
      toast.error("password mismatch", {
        position: toast.POSITION.TOP_CENTER,
      });
      setLoading(false);
    }

   
  };

  function Button() {
    if (
      fullname.length &&
      emailr.length &&
      number.length &&
      password.length >= 8 &&
      confirmPassword.length &&
      password === confirmPassword
    ) {
      return (
        <>
          <button type="submit">{loading ? "Signing Up..." : "Sign Up"}</button>
          <br />
          <br />
        </>
      );
    } else {
      return (
        <>
          <button disabled type="submit">
            Sign up
          </button>
          <br />
          <br />
        </>
      );
    }
  }

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [bankName, setBankName] = useState("");

  const [compressedFile, setCompressedFile] = useState(null);

  const [ppUploadBtn, setppUploadBtn] = useState(false);

  const [profilePic, setProfilePic] = useState("");

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loadingx, setLoadingx] = useState(false);

  const [routes, setRoutes] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  // const [loading, setLoading] = useState(false);

  const [dType, setDType] = useState([]);

  const [plateNumber, setPlateNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vLicenseNumber, setVLicenseNumber] = useState("");
  const [vIssueDate, setVIssueDate] = useState("");
  const [vExpiryDate, setVExpiryDate] = useState("");
  const [vLicenseImg, setVLicenseImg] = useState("");
  const [goodsImagePrev2, setGoodsImagePrev2] = useState([]);
  const [vImg, setVImg] = useState([]);
  const [goodsImagePrev3, setGoodsImagePrev3] = useState([]);

  const [regCert, setRegCert] = useState("");
  const [vehicleInsurance, setVehicleInsurance] = useState("");
  const [carrierPermit, setCarrierPermit] = useState("");
  const [touristTaxi, setTouristTaxi] = useState("");
  const [roadWorth, setRoadWorth] = useState("");
  const [hackeyPermit, setHackneyPermit] = useState("");
  const [vType, setVType] = useState("");

  const doci = new FormData();

  doci.append("regCertImage", regCert);
  doci.append("vehicleInsuranceImage", vehicleInsurance);
  doci.append("carriagePermitImage", carrierPermit);

  const doci2 = new FormData();

  doci2.append("touristTaxiPermitImage", touristTaxi);
  doci2.append("roadworthinessCertImage", roadWorth);
  doci2.append("hackneyPermitImage", hackeyPermit);

  const handleDocSubmit = async (e) => {
    e.preventDefault();
    setD1Loader(true);
    try {
      const { data } = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        doci,
        config
      );
      console.log(data);
      toast.success(data.message);
      setD1Loader(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
      setD1Loader(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const handleDocSubmit2 = async (e) => {
    e.preventDefault();
    setD2Loader(true);
    try {
      const { data } = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        doci2,
        config
      );
      console.log(data);
      toast.success(data.message);
      setD2Loader(false);
    } catch (error) {
      console.log(error?.response?.data?.message);
      setD2Loader(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const vformData = new FormData();
  vformData.append("vehicleInformationAndLicense[plateNumber]", "xxxxxx");
  vformData.append("vehicleInformationAndLicense[vehicleType]", vehicleType);
  vformData.append("vehicleInformationAndLicense[issueDate]", "xxxxxx");
  vformData.append("vehicleInformationAndLicense[expiryDate]", "xxxxxx");
  vformData.append(
    "vehicleInformationAndLicense[vehicleLicenseNumber]",
    "xxxxxx"
  );
  vformData.append("profilePicture", vLicenseImg);
  vformData.append("vehicleImages", vImg);

  const vehicletempData = vformData;

  const handleVehicleLicenseSumbit = async (e) => {
    e.preventDefault();
    setVLoader(true);
    console.log(vehicletempData);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        vehicletempData,
        config
      );
      console.log(res.data);
      setVLoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      // window.location.reload(false);
    } catch (error) {
      console.log(error.response.data.message);
      setVLoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const [dLicenseNumber, setDLicenseNumber] = useState("");
  const [dIssueDate, setDIssueDate] = useState("");
  const [dExpiryDate, setDExpiryDate] = useState("");
  const [dLicenseImg, setDLicenseImg] = useState("");
  const [goodsImagePrev1, setGoodsImagePrev1] = useState([]);

  const dformData = new FormData();
  dformData.append(
    "driverLicenseInformation[driverLicenseNumber]",
    dLicenseNumber
  );
  dformData.append("driverLicenseInformation[issueDate]", dIssueDate);
  dformData.append("driverLicenseInformation[expiryDate]", dExpiryDate);
  dformData.append("driverLicenseImage", dLicenseImg);

  const drivertempData = dformData;

  const handleDriverLicenseSumbit = async (e) => {
    e.preventDefault();
    setDLoader(true);
    console.log(drivertempData);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        drivertempData,
        config
      );
      console.log(res.data);
      setDLoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      // window.location.reload(false);
    } catch (error) {
      console.log(error.response.data.message);
      setDLoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [selected, setSelected] = useState([]);
  const [ids, setIds] = useState([]);
  const [rLoader, setRLoader] = useState(false);

  const handleRoutes = (i) => {
    setSelected((prev) =>
      selected.includes(i) ? prev.filter((cur) => cur !== i._id) : [...prev, i]
    );
    if (!ids.includes(i._id)) {
      setIds([...ids, i._id]);
    } else {
      alert("you cannot select this again");
    }
  };

  const allSelectedRoutes = {
    route: selected,
  };

  const finalData = new FormData();



  const handleOfflineSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(finalData);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.WEHAUL_DRIVER_TOKEN}`,
      },
    };
    try {
      const res = await axios.post(
        `${baseUrl}/wehaul/driver/create`,
        finalData,
        config
      );
      console.log(res.data);
      setLoading(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      window.location.reload();
    } catch (error) {
      console.log(error.response.data.message);
      setLoading(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const personalData = {
    phoneNumber: phoneNumber,
    address: address,
  };

  const handlePersonalSubmit = async (e) => {
    e.preventDefault();
    setPLoader(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        personalData,
        config
      );
      console.log(res.data);
      setPLoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.log(error.response.data.message);
      setPLoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const tempData = {
    accountInformation: {
      accountName,
      accountNumber,
      accountType,
      bankName,
    },
  };

  const handleAcctSumbit = async (e) => {
    e.preventDefault();
    setALoader(true);
    console.log(tempData);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        tempData,
        config
      );
      console.log(res.data);
      setALoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      console.log(error.response.data.message);
      setALoader(false);
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  //   const handlePPchange = (e) => {
  //     setppUploadBtn(true);
  //     setProfilePic(e.target.files[0]);
  //   };

  const uploadImg = async (e) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.ONBOARDING_TOKEN}`,
      },
    };
    const formData = new FormData();
    formData.append("profilePicture", compressedFile);
    setLoadingx(true);

    try {
      const res = await axios.put(
        `${baseUrl}/driver/settings/edit-profile`,
        formData,
        config
      );
      console.log(res.data);
      setLoadingx(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setppUploadBtn(false);
      // window.location.reload();
    } catch (error) {
      console.log(error);
      setLoadingx(false);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleCompressedUpload = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        setppUploadBtn(true);
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setCompressedFile(compressedResult);
        console.log(compressedResult);
      },
    });
  };

  const clearToken = async () => {
    setFinalLoader(true);
    await localStorage.removeItem("ONBOARDING_TOKEN");
    window.location.reload();
    window.scrollTo(0, 0);
  };


  const [docs, setDocs] = useState([]);

  

  const handleDocs = (e) => {
    let newArray = [...docs, e.target.value];
    if (docs.includes(e.target.value)) {
      newArray = newArray.filter((d) => d !== e.target.value);
    }
    setDocs(newArray);
  };
  const handleRoutez = (e) => {
    let newArrayz = [...selected, e.target.value];
    if (selected.includes(e.target.value)) {
      newArrayz = newArrayz.filter((d) => d !== e.target.value);
    }
    setSelected(newArrayz);
  };

  const selectBrand = (e) => {
    setBrand(e.target.value);
    setTempModel(vehiclesData.find((i) => i.brand === e.target.value).models);
  };
  const selectModel = (e) => {
    setModel(e.target.value);
    // setModel(vehiclesData.find(i => i.brand === e.target.value).models);
  };

  const showRes = () => {
    console.log(selected);
  };

  const dTypex = [
    {
      label: "Food",
      value: "food",
    },
    {
      label: "Fragile",
      value: "fragile",
    },
    {
      label: "Animal/Pets",
      value: "animals",
    },
    {
      label: "Documents",
      value: "documents",
    },
    {
      label: "Edibles",
      value: "edibles",
    },
    {
      label: "Others",
      value: "others",
    },
  ];

  const handleChange = (selected) => {
    setDType(selected);
    console.log(`Option selected:`, selected);
  };

  const addNewRoutes = async (e) => {
    e.preventDefault();
    setRLoader(true);
    setShowModal(true);
    try {
      const res = await axios.put(
        `${baseUrl}/driver/add-preferred-route`,
        allSelectedRoutes,
        config
      );
      console.log(res.data);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setRLoader(false);
      setMsg(res?.data?.message);
      //  window.location.reload();
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setRLoader(false);
    }
  };

  const handleCompressedUpload1 = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setProfilePic(compressedResult);
        console.log(compressedResult);
      },
    });
  };
  const handleCompressedUpload2 = (e) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        // compressedResult has the compressed file.
        // Use the compressed file to upload the images to your server.
        setVImg(compressedResult);
        console.log(compressedResult);
      },
    });
  };

 
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [cloading, setCloading] = useState(false);
  const [cloading2, setCloading2] = useState(false);

  const uploadMedia1 = async () => {

    const datam = new FormData();
    datam.append("file", profilePic);
    datam.append("upload_preset", "onboarding");
    datam.append("cloud_name", "Wehaul Logistics");

    setCloading(true);

    try {
      const res = await axios.post("https://api.cloudinary.com/v1_1/wehaul-logistics/image/upload", datam)
      console.log(res)
      setUrl1(res?.data?.url)
      setCloading(false)
      if(res.status === 200) {
        toast.success("image saved successfully")
      }
    } catch (err) {
      console.log(err)
      toast.error("An error occured, please check network connectivity")
      setCloading(false)
    }
  };

  const uploadMedia2 = async () => {

    const datam = new FormData();
    datam.append("file", vImg);
    datam.append("upload_preset", "onboarding");
    datam.append("cloud_name", "Wehaul Logistics");

    setCloading2(true);

    try {
      const res = await axios.post("https://api.cloudinary.com/v1_1/wehaul-logistics/image/upload", datam)
      console.log(res.data)
      setUrl2(res?.data?.url)
      setCloading2(false)
      if(res.status === 200) {
        toast.success("image saved successfully")
      }
    } catch (err) {
      console.log(err)
      toast.error("An error occured, please check network connectivity")
      setCloading2(false)
    }
  };
  
  const datazy = new FormData();

  datazy.append("vehicleBrand", brand);
  datazy.append("vehicleModel", model);
  datazy.append("vehicleType", vehicleTypex);
  // datazy.append("vehicleInformationAndLicense[vehicleType]", vehicleTypex);
  // datazy.append("vehicleInformationAndLicense[plateNumber]", "xxxxxx");
  // datazy.append("vehicleInformationAndLicense[issueDate]", "xxxxxx");
  // datazy.append("vehicleInformationAndLicense[expiryDate]", "xxxxxx");
  // datazy.append(
  //   "vehicleInformationAndLicense[vehicleLicenseNumber]",
  //   "xxxxxx"
  // );
  datazy.append("isSmartphone", smartPhone);
  datazy.append("vehicleLocation", vehicleLoc);
  datazy.append("providedDocs", docs);
  datazy.append("providedDocs", docs);
  datazy.append("name", fullname);
  datazy.append("phoneNumber", number);
  // datazy.append("email", emailr)
  datazy.append("preferredRoutes", selected);
  datazy.append("preferredRoutes", selected);
  datazy.append("carriagePermitImage", url1);
  datazy.append("vehicleInsuranceImage", url2);
  datazy.append("referralCode", refCode);

  const dataMerge = async (e) => {
    e.preventDefault();
    setPLoader(true);
    setShowModal(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.WEHAUL_DRIVER_TOKEN}`,
      },
    };
    try {
      const res = await axios.post(
        `${baseUrl}/wehaul/driver/create`,
        datazy,
        config
      );
      console.log(res.data);
      setPLoader(false);
      toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setMsg(res?.data?.message);
      setResType("success");
      // window.location.reload();
    } catch (error) {
      console.log(error.response.data.message);
      setPLoader(false);
      setMsg(error?.response?.data?.message);
      setResType("failure");
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div className="settings">
      {showModal && (
        <SuccessM
          resType={resType}
          msg={msg}
          loading={pLoader}
          setTokenBox={setShowModal}
        />
      )}

      <div className="cover">
        <form onSubmit={dataMerge} className="form">
          <div className="personal-info">
            <div className="inputs">
              <div className="input">
                <label>Name</label>
                <input
                  type="text"
                  placeholder="Full Name"
                  required
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />{" "}
              </div>
              <div className="input">
                <label>Phone Number</label>
                <input
                  type="tel"
                  placeholder="Phone Number"
                  required
                  minLength={11}
                  maxLength={11}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />{" "}
              </div>

              <br />

              <br />

              <div className="input">
                <label htmlFor="phone-number">Vehicle Type</label>
                <br />
                <i className="fas fa-circle"></i>
                <select
                  id="phone-number"
                  // value={brand}
                  onChange={e => setVehicleTypex(e.target.value)}
                >
                  <option selected disabled>
                    Select Vehicle Type
                  </option>
                  <option value="buses">Buses</option>
                  <option value="vans">Vans</option>
                  <option value="suvs and space-buses">Suvs</option>
                  <option value="trucks">Trucks</option>
                  <option value="bikes">Bikes</option>
                  <option value="cars">Cars</option>
                  {/* {vehiclesData.map((i, index) => {
                    return (
                      <option key={index} value={i.brand}>
                        {i.brand}
                      </option>
                    );
                  })} */}
                </select>
              </div>

              <div className="input">
                <label htmlFor="phone-number">Vehicle Brand</label>
                <br />
                <i className="fas fa-circle"></i>
                <select
                  id="phone-number"
                  // value={brand}
                  onChange={selectBrand}
                >
                  <option selected disabled>
                    Select Vehicle Brand
                  </option>
                  {vehiclesData.map((i, index) => {
                    return (
                      <option key={index} value={i.brand}>
                        {i.brand}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="input">
                <label htmlFor="phone-number">Vehicle Model</label>
                <br />
                <i className="fas fa-circle"></i>
                <select
                  id="phone-number"
                  // value={model}
                  onChange={selectModel}
                >
                  <option selected disabled>
                    Select Vehicle Model
                  </option>
                  {tempModel.map((i, index) => {
                    return (
                      <option key={index} value={i}>
                        {i}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="input">
                <label htmlFor="phone-number">
                  Does Driver own a smartphone ?
                </label>
                <br />
                <select
                  id="phone-number"
                  onChange={(e) => setSmartPhone(e.target.value)}
                >
                  <option selected disabled>
                    --select--
                  </option>
                  <option value="true">Yes</option>
                  <option value="false">No</option>
                </select>
              </div>

              <div className="input">
                <label htmlFor="phone-number">Vehicle Location</label>
                <br />
                <input
                  type="text"
                  value={vehicleLoc}
                  onChange={(e) => setVehicleLoc(e.target.value)}
                />
                {/* <p onClick={showRes}>go</p> */}
              </div>

              <div className="input">
                <label htmlFor="phone-number">Promoter's Referral Code</label>
                <br />
                <input
                  type="text"
                  required
                  style={{ textTransform: "uppercase" }}
                  value={refCode}
                  onChange={(e) => setRefCode(e.target.value.toUpperCase())}
                />
              </div>

              <br />
              <br />
              <div className="routes">
                <p className="title">Available Documents</p>
                <div className="route">
                  <input
                    type="checkbox"
                    value="Drivers License"
                    onChange={(e) => handleDocs(e)}
                  />
                  <p className="item">Drivers License</p>
                </div>
                <div className="route">
                  <input
                    type="checkbox"
                    value="Road Worthiness"
                    onChange={(e) => handleDocs(e)}
                  />
                  <p className="item">Road Worthiness</p>
                </div>
                <div className="route">
                  <input
                    type="checkbox"
                    value="Insurance"
                    onChange={(e) => handleDocs(e)}
                  />
                  <p className="item">Insurance</p>
                </div>
              </div>
            </div>
            <br />

            <div className="business-info">
              <div className="title">Driver Picture</div>
              <div className="all-inputs">
                <div className="left-inputs"></div>

                <div className="right-inputs">
                  <div
                    className="input"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  ></div>

                  <div className="files">
                    <div className="img-upload field-div">
                      <div className="selector">
                        <div className="action">
                          <input
                            onChange={handleCompressedUpload1}
                            type="file"
                            id="file"
                          />
                          {/* <i className="fas fa-upload"></i> */}
                          <br />
                          <br />
                          <p>Upload Driver Picture</p>
                        </div>
                        <div
                          style={{
                            height: "142px",
                            borderRadius: "0 0 5px 5px",
                            backgroundImage: `url(${goodsImagePrev2})`,
                            backgroundSize: "cover",
                          }}
                          className="preview"
                        ></div>
                      </div>
                    </div>

      
                  </div>
                  <br />
                  {url1 ?  <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}}>Saved</p> : <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}} onClick={uploadMedia1}>{cloading ? "Saving..." : "Save"}</p>}
                  
                </div>
              </div>
              <br />
            </div>
            <div className="business-info">
              <div className="title">Vehicle Image</div>
              <div className="all-inputs">
                <div className="left-inputs"></div>

                <div className="right-inputs">
                  <div
                    className="input"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  ></div>

                  <div className="files">
                    

                    <div className="img-upload field-div">
                      <div className="selector">
                        <div className="action">
                          <input
                            onChange={handleCompressedUpload2}
                            type="file"
                            id="file"
                          />
                          {/* <i className="fas fa-upload"></i> */}
                          <br />
                          <br />
                          <p>Upload Vehicle Image</p>
                        </div>
                        <div
                          style={{
                            height: "142px",
                            borderRadius: "0 0 5px 5px",
                            backgroundImage: `url(${goodsImagePrev3})`,
                            backgroundSize: "cover",
                          }}
                          className="preview"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <br />
                  {url2 ?  <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}}>Saved</p> : <p className="btnx" style={{padding: "12px 15px", borderRadius: "6px", color: "#fff", backgroundColor: "#113E82", width: "fit-content"}} onClick={uploadMedia2}>{cloading2 ? "Saving..." : "Save"}</p>}
                </div>
              </div>
              <br />
            </div>
          </div>

          {vehicleType !== "dyna" && (
            <div className="personal-info">
              <p className="title">Preferred Routes</p>
              <br />

              <div className="routes">
                {routesx.map((i, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "13px",
                        alignItems: "center",
                      }}
                      className="route"
                      key={index}
                    >
                      <input
                        style={{ transform: "scale(1.7)", marginRight: "30px" }}
                        type="checkbox"
                        value={i}
                        onChange={(e) => handleRoutez(e)}
                      />
                      <p style={{ fontWeight: "600" }} className="item">
                        {capitalizeFirstLetter(i)}
                      </p>
                    </div>
                  );
                })}
              </div>
              {/* <button onClick={addNewRoutes}>{rLoader ? "Saving" : "Save"}</button> */}
            </div>
          )}

          {vehicleType === "dyna" && (
            <div className="personal-info">
              <p className="title">Preferred States</p>
              <br />

              <div className="routes">
                {states.map((i) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        marginBottom: "13px",
                        alignItems: "center",
                      }}
                      className="route"
                      key={i?._id}
                    >
                      <input
                        style={{ transform: "scale(1.7)", marginRight: "30px" }}
                        type="checkbox"
                        onChange={() => handleRoutes(i)}
                      />
                      <p style={{ fontWeight: "600" }} className="item">
                        {capitalizeFirstLetter(i)}
                      </p>
                    </div>
                  );
                })}
              </div>
             
              {/* <button onClick={addNewRoutes}>{rLoader ? "Saving" : "Save"}</button> */}
            </div>
          )}
          <br />
          <div className="btn" style={{ textAlign: "center" }}>
            <button
              type="submit"
              style={{ padding: "20px 70px", fontSize: "18px" }}
            >
              {finalLoader ? "Please Wait..." : "Add Driver"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Offline;
