import './App.css';
import { useEffect } from 'react';
import Aos from 'aos'
import "aos/dist/aos.css"
import Banner from './components/banner/banner';
import Drivers from './components/drivers/drivers';
import Faqs from './components/faqs/faqs';
import Footer from './components/footer/footer';
import Hero from './components/hero/hero';
import Navbar from './components/navbar/navbar';
import UseCases from './components/useCases/useCases';
import Usps from './components/usps/usps';
import Vehicles from './components/vehicles/vehicles';
import WorkFlow from './components/workflow/workflow';
import { Switch, Route} from 'react-router-dom';
import Customer from './pages/customer/customer';
import 'react-toastify/dist/ReactToastify.css';
import Driver from './pages/driver/driver';
import { ToastContainer } from 'react-toastify';
import Privacy from './pages/privacy/privacy';
import DriverPrivacy from './pages/driverPrivacy/driverPrivacy';
import Onboarding from './pages/onboarding/onboarding';
import "react-whatsapp-chat-widget/index.css";
import WhatsAppWidget from 'react-whatsapp-chat-widget';
import "react-whatsapp-chat-widget/index.css";
import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react';
import Wi from './components/vehicles/wicon.png';
import TrackDelivery from './pages/trackDelivery/trackDelivery';
import GuestRequest from './pages/guestRequest/guestRequest';
import TrackDeliveryx from './pages/trackDelivery copy/trackDelivery';
import RequestForm from './pages/requestForm/requestForm';


function App() {
  useEffect(() => {
    Aos.init({duration: 1000});
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Navbar />
	  <Image display={{base: "none", md: "block"}} position="fixed" bottom={"3%"} right={"3%"} zIndex="10000000" onClick={() => window.open('https://wa.me/2349160000687', "_blank") || window.location.replace('https://wa.me/2349160000687')} cursor={"pointer"}  width={{base: "30%", md: "11%"}} src={Wi} />
	  
	  <Box onClick={() => window.open('https://wa.me/2349160000687', "_blank") || window.location.replace('https://wa.me/2349160000687')} display={{base: "block", md: "none"}} position="fixed" width={"100%"} zIndex="10000000" bottom="0%">
		<Flex alignItems={"center"} justifyContent="center" fontWeight={"600"} padding={"1rem .7rem"} bg="whatsapp.400">
			<Text mr=".5rem">Request to move your items via Whatsapp</Text>
			<Image width={"15%"} src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png" />
		</Flex>
 	  </Box>

      <Switch>
        <Route exact path="/" component={Customer} />
        <Route exact path="/driver" component={Driver} />
        <Route exact path="/request" component={RequestForm} />
        <Route exact path="/privacy_policy" component={Privacy} />
        <Route exact path="/onboarding" component={Onboarding} />
        <Route exact path="/driver-privacy-policy" component={DriverPrivacy} />
        <Route exact path="/trackDelivery" component={TrackDelivery} />
        <Route exact path="/trackDelivery/:tid/:rid" component={TrackDeliveryx} />
		<Route exact path='/viewTripDetails' component={GuestRequest} />
      </Switch>
      {/* <UseCases />
      <Banner />
      <Faqs />
      <Footer /> */}

		


{/* <WhatsAppWidget
			phoneNo="2349160000687"
			position="right"
			autoOpen={false}
			autoOpenTimer={5000}
			messageBox={true}
			messageBoxTxt=""
			iconSize="60"
			iconColor="white"
			iconBgColor="#2ec785"
			headerIcon="http://localhost:3001/static/media/wehaulogo.560874e5c6e38b78689a.png"
			headerIconColor="pink"
			headerTxtColor="white"
			headerBgColor="#424bf2"
			headerTitle="WeHaul247 Support"
			headerCaption={<Badge fontSize="10px" borderRadius="10px" colorScheme="green">Online</Badge>}
			bodyBgColor="#ddd"
			chatPersonName="WeHaul247 Support"
			chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
			footerBgColor="#bbb"
			btnBgColor="#2ec785"
			btnTxtColor="white"
		/> */}
    </div>
  );
}

export default App;
