import './whyuse.scss';

const data = [
    {
        label: "No Commission",
        val: "First logistics hailing platform that takes no commission from driver."
    },
    {
        label: "Set Your Own Price",
        val: "Drivrs have full control and can set their own prices to pick any item"
    },
    {
        label: "Same Day Cash-out",
        val: "Easily transfer your earnings to your bank account."
    },
    {
        label: "Multiple Pick-Up",
        val: "There is no limit to how many orders a rider/driver can pick up simultaneously"
    },
    {
        label: "Work at your own pace",
        val: "Be your own boss, decide when you work and where you want to go."
    }
]

const Whyuse = () => {
    return (
        <div className="why-use">
            <p className="titlexx">Why use us</p>
            <div className="content">
                {data.map(({label, val}, index) => {
                    return (
                        <div data-aos="fade-right" className="opt">
                            <div className="left"><span>{index+1}</span>{label}</div><div className="right">{val}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Whyuse;