import Drivers from "../../components/drivers/drivers"
import Hero from "../../components/hero/hero"
import Usps from "../../components/usps/usps"
import UseCases from '../../components/useCases/useCases';
import Banner from '../../components/banner/banner';
import Faqs from '../../components/faqs/faqs';
import Footer from '../../components/footer/footer';
import Vehicles from "../../components/vehicles/vehicles";
import WorkFlow from "../../components/workflow/workflow";
import Navbar from "../../components/navbar/navbar";
import Blog from "../../components/blog/blog";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import './customer.css'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const Customer = () => {
    const history = useHistory()
    return (
        <div className="customer">
            <Flex rowGap="1rem" width="100%" 
            borderTop={"1px solid #000"}
            // bg="rgba(242, 160, 7, .7)" 
            bg="#fff"
            flexWrap="wrap"
            justifyContent={"space-between"} alignItems="center" px={[".7rem","2rem"]}>
                <Box flex={"1 1 400px"} py="1rem" pr={["0", "2rem"]} boxSizing="border-box" display={"flex"} alignItems="center" justifyContent={"space-between"} gap="1rem">
                    <Text fontFamily={"Space Grotesk"} fontSize={["1rem","1.8rem"]} fontWeight={"800"} lineHeight={["20px", "35px"]}>Move anything with us</Text>
                    {/* <Button onClick={() => window.open('https://wa.me/2349160000687', "_blank") || window.location.replace('https://wa.me/2349160000687')} w={200} mt={2} bg="rgba(242, 160, 7, .7)" padding="1.6rem 0" fontSize='14px' transition="250ms ease" color='#121212' border="1px solid #555039" borderRadius={"0"} boxShadow={"rgb(18, 18, 18) 5px 5px"}>REQUEST PICKUP</Button> */}
                    <Button onClick={() => history.push('/request')} w={200} mt={2} bg="rgba(242, 160, 7, .7)" padding="1.6rem 0" fontSize='14px' transition="250ms ease" color='#121212' border="1px solid #555039" borderRadius={"0"} boxShadow={"rgb(18, 18, 18) 5px 5px"}>REQUEST PICKUP</Button>
                </Box>
                {/* <Box flex={"1 1 400px"} borderLeft={["none","2px solid #000"]} borderTop={["2px solid #000", "none"]} py="1rem" pl={["0", "2rem"]} boxSizing="border-box" display={"flex"} alignItems="center" justifyContent={"space-between"} gap="1rem">
                    <Text fontFamily={"Space Grotesk"} maxW="500px" fontSize={["1rem","1.8rem"]} fontWeight={"800"} lineHeight={["20px", "35px"]}>Relocate & Move to your new home with ease</Text>
                    <a href="https://wehaul247movers.com/" target="_blank" rel="noopener noreferrer"><Button w={200} mt={2} bg="rgba(242, 160, 7, .7)" padding="1.6rem 0" fontSize='14px' transition="250ms ease" color='#121212' border="1px solid #555039" borderRadius={"0"} boxShadow={"rgb(18, 18, 18) 5px 5px"}>REQUEST RELOCATION</Button></a>
                </Box> */}
            </Flex>
            <Hero />
            <Vehicles />
            <WorkFlow bg="#ecf5fd" />
            <Usps />
            <Drivers />
            <UseCases />
            <Banner />
            <Faqs />
            <Blog />
            <Footer />
        </div>
    )
}

export default Customer