import './hero.scss';
import TextLoop from 'react-text-loop';
import ImgHero from './herobgx.jpg';
import { Box, Text } from '@chakra-ui/react';

const Hero = () => {

    return (

        <Box paddingTop={{base: "100px", md: "25px"}} pl={{base: "10px", md: "4rem"}} pr={{base: "10px", md: "4rem"}} height={{base: "auto", md: "390px"}}>
            <Box position={"absolute"} backgroundImage={ImgHero} backgroundSize="cover" height={{base: "160px", md: "390px"}} top={"0"} bottom="0" left={"0"} right="0"></Box>
            <Box alignItems={"center"} padding={{base: "1rem", md:"2rem"}} height="340px" borderRadius={"10px"} maxW="550px" bg="#fff">
                <h1 className="bld-txt" style={{fontSize: "2.4rem", marginTop: ".5rem"}}>Move your <TextLoop><span>Gas Cooker</span><span>Food Items</span><span>Books/Tools</span><span>Generator</span><span>Pets/Animals</span></TextLoop> <br /> from one location to the other</h1>
                <Text maxW="450px" mt={8} color={"gray"} fontSize={{base:"1.2rem", md: "1.5rem"}} lineHeight={"30px"}>We provide the most affordable,reliable and rapid way to deliver large items within and outside Lagos, Nigeria.</Text>
            </Box>
        </Box>

    )
}

export default Hero;