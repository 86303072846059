import './driBanner.scss';
import Google from '../../../assets/google-download.png';
import Apple from '../../../assets/apple-download.png';

const DriBanner = () => {
    return (
        <div className="dri-banner">
            <div className="top-x">Move it, anytime, anywhere</div>
            <p className="bold">Be your own Boss, <br /> Make money moving items.</p>
            <p className="para">With your Driver's License and complete vehicle registration documents, you can start making money delivering items across your city.</p>
            <div className="flex-line">
                        <div className="btn" onClick={() => window.location.href = "https://driver.wehaul247.com/signup"}>
                            <button>Register Now</button>
                            <i className="fas fa-arrow-right"></i>
                        </div>
                        
                        <p className="or">or</p>
                        <div className="imgs">
                            <div style={{marginRight: "50px", backgroundImage: `url(${Google})`, transform: "scale(1.4)"}} className="imgx"></div>
                            <div style={{backgroundImage: `url(${Apple})`, transform: "scale(1.4)"}} className="imgx"></div>
                        </div>
            </div>
            <p className="info">REGISTER TO START RECEIVING REQUESTS NOW</p>
        </div>
    )
}

export default DriBanner;