import React, { useState } from 'react';
import './successM.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import ButtonLoader from '../../../components/buttonLoader/buttonLoader';

const SuccessM = ({setTokenBox, msg, resType, loading}) => {

    const handleX = async () => {
        await localStorage.removeItem("ONBOARDING_TOKEN")
        await setTokenBox(false);
        await window.location.reload();

        window.scroll(0,0)
    }
    const handleXY = async () => {
        await setTokenBox(false);
        // await window.location.reload();

        // window.scroll(0,0)
    }
   

    return ( 
        <div className="amount-modal">
            <div className="wrapper">
                <div className="modal">
                <i onClick={resType === "success" ? handleX : handleXY} className="fas fa-times"></i>
                    <div style={{padding: "2rem"}} className="cont">
                    <p>{loading ? "Adding Driver" : msg}</p>
                    <div className="icon" style={{textAlign: "center"}}>
                        <br />
                    {loading ? "Please Wait" : resType === "success" ? <i style={{backgroundColor: "green", color: "#fff", padding: "7px", borderRadius: "50%", transform: "scale(1.7)"}} className="fas fa-check"></i> : <i style={{backgroundColor: "red", color: "#fff", padding: "7px 12px", borderRadius: "50%", transform: "scale(1.7)"}} className="fas fa-exclamation"></i>}
                    </div>
                     
                    </div>
                    
                </div>
            </div>
        </div>
     );
}
 
export default SuccessM;