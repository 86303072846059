import './banner.scss';
import Img from '../../assets/6.jpg';

const Banner = () => {

    const handleApps2 = () => {
        console.log("works")
        if(navigator.userAgent.toLowerCase().indexOf("android") > -1){
          window.location.href = "https://play.google.com/store/apps/details?id=com.wehaul.customer";
        } else if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
          window.location.href = 'http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8';
        } else return
      }

    return (
        <div style={{backgroundImage: `url(${Img})`}} className="banner">
            <div className="overlay"></div>
            <p className="bold">Move your Items with Ease <br /> from Point A to B.</p>

            <p className="info">Currently operates only in Lagos.</p>
        </div>
    )
}

export default Banner;