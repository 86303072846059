const routesxy =[
    {
        "_id": "6200e60c1defce0013c782cd",
        "name": "iba junction bus stop, lagos, nigeria",
        "description": "Iba Junction Bus Stop, Lagos, Nigeria is Iba Junction Bus Stop, Lagos, Nigeria"
    },
    {
        "_id": "6200e5e31defce0013c782ca",
        "name": "ojo barracks, school of music, barracks, nigeria",
        "description": "Ojo barracks, School of Music, barracks, Nigeria is Ojo barracks, School of Music, barracks, Nigeria"
    },
    {
        "_id": "6200e5bf1defce0013c782c7",
        "name": "ajegunle, lagos, nigeria",
        "description": "Ajegunle, Lagos, Nigeria is Ajegunle, Lagos, Nigeria"
    },
    {
        "_id": "6200e5b71defce0013c782c4",
        "name": "ajao estate, lagos, nigeria",
        "description": "Ajao Estate, Lagos, Nigeria is Ajao Estate, Lagos, Nigeria"
    },
    {
        "_id": "6200e5b01defce0013c782c1",
        "name": "apapa, lagos, nigeria",
        "description": "Apapa, Lagos, Nigeria is Apapa, Lagos, Nigeria"
    },
    {
        "_id": "6200e5a61defce0013c782be",
        "name": "sango ota, agege, nigeria",
        "description": "Sango Ota, Agege, Nigeria is Sango Ota, Agege, Nigeria"
    },
    {
        "_id": "6200e5991defce0013c782bb",
        "name": "surulere, lagos, nigeria",
        "description": "Surulere, Lagos, Nigeria is Surulere, Lagos, Nigeria"
    },
    {
        "_id": "6200e5901defce0013c782b8",
        "name": "ebute metta, lagos, nigeria",
        "description": "Ebute Metta, Lagos, Nigeria is Ebute Metta, Lagos, Nigeria"
    },
    {
        "_id": "6200e5861defce0013c782b5",
        "name": "bariga, oworonshoki, nigeria",
        "description": "Bariga, Oworonshoki, Nigeria is Bariga, Oworonshoki, Nigeria"
    },
    {
        "_id": "6200e57e1defce0013c782b2",
        "name": "oyingbo market adekunle lagos, market street, lagos, nigeria",
        "description": "Oyingbo Market Adekunle Lagos, Market Street, Lagos, Nigeria is Oyingbo Market Adekunle Lagos, Market Street, Lagos, Nigeria"
    },
    {
        "_id": "6200e5731defce0013c782af",
        "name": "berger bus-stop, ojodu berger, nigeria",
        "description": "Berger Bus-Stop, Ojodu Berger, Nigeria is Berger Bus-Stop, Ojodu Berger, Nigeria"
    },
    {
        "_id": "6200e56c1defce0013c782ac",
        "name": "ibafo, nigeria",
        "description": "Ibafo, Nigeria is Ibafo, Nigeria"
    },
    {
        "_id": "6200e5621defce0013c782a9",
        "name": "mowe, loburo, nigeria",
        "description": "Mowe, Loburo, Nigeria is Mowe, Loburo, Nigeria"
    },
    {
        "_id": "6200e5511defce0013c782a6",
        "name": "ogba, lagos, nigeria",
        "description": "Ogba, Lagos, Nigeria is Ogba, Lagos, Nigeria"
    },
    {
        "_id": "6200e5491defce0013c782a3",
        "name": "maryland, lagos, nigeria",
        "description": "Maryland, Lagos, Nigeria is Maryland, Lagos, Nigeria"
    },
    {
        "_id": "6200e5191defce0013c7829f",
        "name": "satellite town, lagos, nigeria",
        "description": "Satellite Town, Lagos, Nigeria is Satellite Town, Lagos, Nigeria"
    },
    {
        "_id": "6200e5111defce0013c7829c",
        "name": "somolu, lagos, nigeria",
        "description": "Somolu, Lagos, Nigeria is Somolu, Lagos, Nigeria"
    },
    {
        "_id": "6200e50a1defce0013c78299",
        "name": "ilupeju, ikeja, nigeria",
        "description": "Ilupeju, Ikeja, Nigeria is Ilupeju, Ikeja, Nigeria"
    },
    {
        "_id": "6200e5011defce0013c78296",
        "name": "ketu, lagos, nigeria",
        "description": "Ketu, Lagos, Nigeria is Ketu, Lagos, Nigeria"
    },
    {
        "_id": "6200e4fb1defce0013c78293",
        "name": "ikoyi, lagos, nigeria",
        "description": "Ikoyi, Lagos, Nigeria is Ikoyi, Lagos, Nigeria"
    },
    {
        "_id": "6200e4f11defce0013c78290",
        "name": "victoria island, lagos, nigeria",
        "description": "Victoria Island, Lagos, Nigeria is Victoria Island, Lagos, Nigeria"
    },
    {
        "_id": "6200e4e91defce0013c7828d",
        "name": "ikeja, nigeria",
        "description": "Ikeja, Nigeria is Ikeja, Nigeria"
    },
    {
        "_id": "6200e4dd1defce0013c7828a",
        "name": "yaba bus park, lagos, nigeria",
        "description": "Yaba Bus Park, Lagos, Nigeria is Yaba Bus Park, Lagos, Nigeria"
    },
    {
        "_id": "6200e4d11defce0013c78287",
        "name": "epe, nigeria",
        "description": "Epe, Nigeria is Epe, Nigeria"
    },
    {
        "_id": "6200e4c31defce0013c78284",
        "name": "ikorodu, nigeria",
        "description": "Ikorodu, Nigeria is Ikorodu, Nigeria"
    },
    {
        "_id": "6200e4ba1defce0013c78281",
        "name": "lagos island, lagos, nigeria",
        "description": "Lagos Island, Lagos, Nigeria is Lagos Island, Lagos, Nigeria"
    },
    {
        "_id": "6200e4ab1defce0013c7827e",
        "name": "lekki phase 1, lekki, nigeria",
        "description": "Lekki Phase 1, Lekki, Nigeria is Lekki Phase 1, Lekki, Nigeria"
    },
    {
        "_id": "6200e49d1defce0013c7827b",
        "name": "ajah, lagos, nigeria",
        "description": "Ajah, Lagos, Nigeria is Ajah, Lagos, Nigeria"
    },
    {
        "_id": "6200e4951defce0013c78278",
        "name": "badagry, nigeria",
        "description": "Badagry, Nigeria is Badagry, Nigeria"
    },
    {
        "_id": "6200e4841defce0013c78275",
        "name": "agbara estate, lagos - badagry expy, agbara, nigeria",
        "description": "Agbara Estate, Lagos - Badagry Expy, Agbara, Nigeria is Agbara Estate, Lagos - Badagry Expy, Agbara, Nigeria"
    },
    {
        "_id": "6200e4721defce0013c78272",
        "name": "festac town, lagos, nigeria",
        "description": "Festac Town, Lagos, Nigeria is Festac Town, Lagos, Nigeria"
    },
    {
        "_id": "6200e3dd1defce0013c7826e",
        "name": "isolo general hospital, isolo rd, ikeja, nigeria",
        "description": "Isolo General Hospital, Isolo Rd, Ikeja, Nigeria is Isolo General Hospital, Isolo Rd, Ikeja, Nigeria"
    },
    {
        "_id": "6200e3d31defce0013c7826b",
        "name": "mushin, lagos, nigeria",
        "description": "Mushin, Lagos, Nigeria is Mushin, Lagos, Nigeria"
    },
    {
        "_id": "6200e3cc1defce0013c78268",
        "name": "ogudu gra estate, lagos, nigeria",
        "description": "Ogudu GRA Estate, Lagos, Nigeria is Ogudu GRA Estate, Lagos, Nigeria"
    },
    {
        "_id": "6200e3ae1defce0013c78265",
        "name": "oshodi, lagos, nigeria",
        "description": "Oshodi, Lagos, Nigeria is Oshodi, Lagos, Nigeria"
    },
    {
        "_id": "6200e3a81defce0013c78262",
        "name": "gbagada, lagos, nigeria",
        "description": "Gbagada, Lagos, Nigeria is Gbagada, Lagos, Nigeria"
    },
    {
        "_id": "6200e39d1defce0013c7825f",
        "name": "oworonshoki, oworonshoki, nigeria",
        "description": "Oworonshoki, Oworonshoki, Nigeria is Oworonshoki, Oworonshoki, Nigeria"
    },
    {
        "_id": "6200e3951defce0013c7825c",
        "name": "ojota bus stop, ikorodu road, lagos, nigeria",
        "description": "Ojota Bus Stop, Ikorodu Road, Lagos, Nigeria is Ojota Bus Stop, Ikorodu Road, Lagos, Nigeria"
    },
    {
        "_id": "6200e38d1defce0013c78259",
        "name": "ikosi ketu, lagos, nigeria",
        "description": "Ikosi Ketu, Lagos, Nigeria is Ikosi Ketu, Lagos, Nigeria"
    },
    {
        "_id": "6200e3821defce0013c78256",
        "name": "akowonjo road, lagos, nigeria",
        "description": "Akowonjo Road, Lagos, Nigeria is Akowonjo Road, Lagos, Nigeria"
    },
    {
        "_id": "6200e3691defce0013c78253",
        "name": "igando, lagos, nigeria",
        "description": "Igando, Lagos, Nigeria is Igando, Lagos, Nigeria"
    },
    {
        "_id": "6200e3611defce0013c78250",
        "name": "ikotun, lagos, nigeria",
        "description": "Ikotun, Lagos, Nigeria is Ikotun, Lagos, Nigeria"
    },
    {
        "_id": "6200e3531defce0013c7824d",
        "name": "idimu road, lagos, nigeria",
        "description": "Idimu Road, Lagos, Nigeria is Idimu Road, Lagos, Nigeria"
    },
    {
        "_id": "6200e3461defce0013c7824a",
        "name": "egbeda, akowonjo road, lagos, nigeria",
        "description": "Egbeda, Akowonjo Road, Lagos, Nigeria is Egbeda, Akowonjo Road, Lagos, Nigeria"
    },
    {
        "_id": "6200e33b1defce0013c78247",
        "name": "ipaja, lagos, nigeria",
        "description": "Ipaja, Lagos, Nigeria is Ipaja, Lagos, Nigeria"
    },
    {
        "_id": "6200e3311defce0013c78244",
        "name": "ayobo, lagos, nigeria",
        "description": "Ayobo, Lagos, Nigeria is Ayobo, Lagos, Nigeria"
    },
    {
        "_id": "6200e3241defce0013c78241",
        "name": "oke odo, lagos, nigeria",
        "description": "Oke Odo, Lagos, Nigeria is Oke Odo, Lagos, Nigeria"
    },
    {
        "_id": "6200e3171defce0013c7823e",
        "name": "agbado - ijaiye road, lagos, nigeria",
        "description": "Agbado - Ijaiye Road, Lagos, Nigeria is Agbado - Ijaiye Road, Lagos, Nigeria"
    },
    {
        "_id": "6200e3081defce0013c7823b",
        "name": "aboru - abesan link bridge, lagos, nigeria",
        "description": "Aboru - Abesan Link Bridge, Lagos, Nigeria is Aboru - Abesan Link Bridge, Lagos, Nigeria"
    }
]

export default routesxy;